import React from 'react';
import { useMemo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Fab, Grid2, Tooltip, Alert, AlertTitle, Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { AlertDialog } from 'src/components/base/AlertDialog';
import { useModalDialog } from 'src/components/base/ModalDialog';
import { BottomBar } from 'src/components/base/BottomBar';

import { SubscriptionDialog } from './SubscriptionDialog';
import { PaymentConfirmation } from './PaymentConfirmation';

import { showChargebeePortal, PAYMENT_SOURCES } from 'src/services/chargebee';
import { runAction, runActivity } from 'src/services/utils';
import { useStore } from 'src/stores';

import { SubscriptionItem } from 'src/types/common';
import { SubscriptionCard } from './base/SubscriptionCard';

interface SubscriptionListProps {
  subscription_id?: string;
}

export const SubscriptionList: React.FC<SubscriptionListProps> = observer(({ subscription_id }) => {
  const { userStore } = useStore();

  const messageDialog = useModalDialog();
  const subscriptionDialog = useModalDialog();
  const paymentConfirmationDialog = useModalDialog();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('f');

  const isProceedAvailable = useMemo(() => userStore.subscriptions.some(i => !!i.estimate), [userStore.subscriptions]);
  const filteredSubscriptions = useMemo(() => {
    if (!filter) { return userStore.subscriptions }
    return userStore.subscriptions.filter(({ origin, data: { name, licenses = [] } }) => {
      return (origin?.id && origin.id.toLowerCase().includes(filter)) ||
        (name && name.toLowerCase().includes(filter)) ||
        licenses.some(({ name, license_email }) => (name || '').toLowerCase().includes(filter) || (license_email || '').toLowerCase().includes(filter));
    });
  }, [userStore.subscriptions, filter]);

  useEffect(() => {
    runAction(async () => !userStore.subscriptions.length && (await userStore.fetchSubscriptions()))
      .then(() => {
        const subscription = userStore.subscriptions?.find(({ key }) => key === subscription_id);
        if (!!subscription_id && !subscription) { return userStore.logout() }
        if (subscription) { return handleManageSubscription(subscription) }
      })
  }, []);

  async function handleManageSubscription(subscription: SubscriptionItem) {
    navigate(`/subscriptions/${subscription.key}`);
    const result = await subscriptionDialog.open({ subscription });
    navigate(`/subscriptions`);
    if (result) {
      userStore.modifySubscription(result);
    }
  }

  function handleResetClick() {
    userStore.resetSubscriptionChanges();
  }

  async function handleAddSubscription() {
    const subscription = userStore.createSubscription();
    const result = await subscriptionDialog.open({ subscription });
    if (result) {
      userStore.modifySubscription(result);
    }
  }

  async function handleProceedPayment() {
    const { total, credits } = userStore.subscriptions.reduce((acc, { estimate }) => {
      acc.total += estimate?.unbilled_charge?.amount ?? 0;
      acc.credits += estimate?.credit_note?.total ?? 0;
      return acc;
    }, { total: 0, credits: userStore.customer!.promotional_credits + userStore.customer!.refundable_credits });

    if (userStore.customer!.auto_collection) {
      // Show warning only if user has not paid invoices or unbilled charges
      if (!!userStore.customer!.unpaid_invoices || !!userStore.customer!.unbilled_charges) {
        if (!(await messageDialog.open({
          title: 'Unpaid Invoices', cancelable: true,
          children: `You have unpaid invoices on your account. You can't make any changes that modify the balance of your account until all your invoices are paid. Proceeding will automatically collect all unpaid invoices. Would you like to continue?`
        }))) { return }
      }

      // Ask payment method only if user has auto_collection and has to pay for anything
      if (userStore.customer!.payment_method?.status === 'required' && (!!userStore.customer!.unpaid_invoices || !!userStore.customer!.unbilled_charges || total > credits)) {
        await new Promise((resolve) => showChargebeePortal(PAYMENT_SOURCES, resolve))
        await runAction(async () => await userStore.fetchCustomer());

        if (userStore.customer!.payment_method.status === 'required') {
          return await messageDialog.open({
            title: 'Payment Methods',
            children: <>You need to provide a valid payment method in order to make changes to your subscription(s).<hr />Reach out to <a href="mailto:sales@xappex.com">sales@xappex.com</a> if you would  like to use invoicing for payments.</>
          })
        }
      }
    }

    // Show invoice overview
    const estimates = userStore.subscriptions.filter(i => !!i.estimate).map(({ estimate }) => estimate);
    if (!(await paymentConfirmationDialog.open({ customer: userStore.customer, estimates, plans: userStore.plans }))) { return }

    try {
      await runActivity(() => userStore.proceedPayment());
      userStore.displaySuccessSnack('All changes have been successfully processed.');
    }
    catch (error: any) {
      await messageDialog.open({
        title: 'Charge Failed',
        children: <>Unfortunately, there was a failure when we tried to bill the credit card on your account:<hr />{error.message}<hr />You will need to either use a different card or clear us with your bank so that charges for our products are allowed. All the changes you have made to your subscription(s) are still in place, however, we will roll them back automatically if we still can’t bill the credit card associated with your account within 1 (one) week. Thank you for understanding.</>
      });
      await runAction(async () => await Promise.all([userStore.fetchCustomer(), userStore.fetchSubscriptions()]));
    }
  }

  return (
    <Grid2 container alignItems="stretch" direction="column" justifyContent="center" size={{ xs: 11, md: 7 }} wrap="nowrap" sx={{ pb: '56px' }}>
      {isProceedAvailable &&
        <Alert severity="warning" sx={{ my: 1 }}>
          <AlertTitle>Some changes have been made to your subscription(s). You will need to click on Proceed button at the bottom of the screen for the changes to take effect</AlertTitle>
        </Alert>
      }

      {filteredSubscriptions.map((subscription) => <SubscriptionCard key={subscription.key} subscription={subscription} onEdit={handleManageSubscription} />)}

      <BottomBar position="fixed" color="default">
        <Grid2 container size={{ xs: 11, md: 7 }} direction="row" alignItems="center" justifyContent="space-between">
          <Grid2>
            <Button
              disabled={!isProceedAvailable || userStore.isLoading}
              onClick={handleResetClick}>Reset</Button>

            <Button variant="contained" color="primary" sx={{ ml: 1 }}
              disabled={!isProceedAvailable || userStore.isLoading} loading={userStore.isLoading}
              onClick={handleProceedPayment}>Proceed</Button>
          </Grid2>
          <Tooltip title="Add new subscription" placement="top" enterDelay={500} aria-label="Add">
            <span>
              <Fab onClick={handleAddSubscription} disabled={userStore.isLoading} sx={{ top: -20 }} color="secondary" aria-label="Add">
                <AddIcon />
              </Fab>
            </span>
          </Tooltip>
        </Grid2>
      </BottomBar>

      <AlertDialog {...messageDialog.register()} />

      <SubscriptionDialog {...subscriptionDialog.register()} />

      <PaymentConfirmation {...paymentConfirmationDialog.register()} />

    </Grid2>
  );
})
