import { useMemo, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Grid2, Typography, IconButton, Divider, Switch } from '@mui/material';
import { Card, CardHeader, CardContent } from '@mui/material';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import { AccountCircle as AccountCircleIcon, Business as BusinessIcon, Payment as PaymentIcon, Settings as SettingsIcon, Add as AddIcon } from '@mui/icons-material';
import { AccountBalanceWallet as AccountBalanceWalletIcon, PhonelinkLock as PhonelinkLockIcon } from '@mui/icons-material';

import { TwofcatorEnableDialog, TwofcatorDisableDialog } from './TwofcatorDialog';
import { useModalDialog } from 'src/components/base/ModalDialog';
import { showChargebeePortal, PAYMENT_SOURCES, ADDRESS, ACCOUNT_DETAILS, CARD_STATUS_VALID } from 'src/services/chargebee';
import { Currency } from 'src/components/base/Currency';
import { TypographySkeleton } from 'src/components/base/TypographySkeleton';
import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';

export const Profile = observer(() => {
  const { userStore } = useStore();

  const { customer = {} } = userStore;
  const { promotional_credits = 0, refundable_credits = 0, unbilled_charges = 0 } = customer;
  const isLoading = useMemo(() => !customer.email, [customer.email]);

  const twofectorEnableDialog = useModalDialog();
  const twofectorDisableDialog = useModalDialog();

  function handleShowChargebeePortal(section) {
    showChargebeePortal(section, () => runAction(async () => await userStore.fetchCustomer()));
  }

  async function handleTwoFactorModify() {
    await (customer.tfa ? twofectorDisableDialog.open({}) : twofectorEnableDialog.open({}));
  }

  return (
    <Grid2 size={{ xs: 11, md: 7 }} container direction="column" rowSpacing={1}>

      <Grid2 size={{ xs: "auto" }}>
        <Card>
          <CardHeader title="Account Details" />

          <CardContent>
            <List>
              <AccountDetailsItem customer={customer} isLoading={isLoading} onClick={() => handleShowChargebeePortal(ACCOUNT_DETAILS)} />

              <Divider variant="inset" component="li" />

              <BillingDetailsItem billing_address={customer.billing_address} isLoading={isLoading} onClick={() => handleShowChargebeePortal(ADDRESS)} />

              <Divider variant="inset" component="li" />

              <PaymentMethodsItem customer={customer} isLoading={isLoading} onClick={() => handleShowChargebeePortal(PAYMENT_SOURCES)} />
            </List>

          </CardContent>
        </Card>
      </Grid2>

      {(!!promotional_credits || !!refundable_credits || !!unbilled_charges) && (
        <Grid2 size={{ xs: "auto" }}>
          <Card>
            <CardHeader title="Summary" />

            <CardContent>
              <List>
                <CreditsItem customer={customer} isLoading={isLoading} />
              </List>
            </CardContent>

          </Card>
        </Grid2>
      )}

      <Grid2 size={{ xs: "auto" }}>
        <Card>
          <CardHeader title="Settings" />

          <CardContent>
            <List>
              <TwoFactorItem checked={customer.tfa} isLoading={isLoading} onChange={handleTwoFactorModify} />
            </List>
          </CardContent>

        </Card>
      </Grid2>

      <TwofcatorEnableDialog {...twofectorEnableDialog.register()} />
      <TwofcatorDisableDialog {...twofectorDisableDialog.register()} />

    </Grid2>
  )
})

const AccountDetailsItem = ({ customer, isLoading, onClick }) => {
  const { first_name, last_name, email, phone } = customer;

  return (
    <ListItem alignItems="flex-start"
      secondaryAction={
        <IconButton onClick={onClick} edge="end" aria-label="update" size="large">
          <SettingsIcon />
        </IconButton>
      }>
      <ListItemAvatar>
        <Avatar>
          <AccountCircleIcon />
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={
          <TypographySkeleton loading={isLoading} variant="body1">
            <>{first_name}&nbsp;{last_name}</>
          </TypographySkeleton>
        }
        secondary={
          <TypographySkeleton loading={isLoading} variant="body2" color="textSecondary">
            {email}<br />
            {phone}
          </TypographySkeleton>
        }
      />
    </ListItem>
  );
}

const BillingDetailsItem = ({ billing_address, isLoading, onClick }) => {
  const { first_name, last_name, country, city, line1, state, zip, company } = billing_address || {};

  return (
    <ListItem alignItems="flex-start" secondaryAction={
      <IconButton onClick={onClick} edge="end" aria-label="update" size="large">
        {!!billing_address ? <SettingsIcon /> : <AddIcon />}
      </IconButton>
    }>
      <ListItemAvatar>
        <Avatar>
          <BusinessIcon />
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={<Typography variant="body1">Billing Info</Typography>}
        secondary={!!billing_address
          ? <Typography variant="body2" color="textSecondary">
            {company}<br />
            <>{first_name}&nbsp;{last_name}</><br />
            <>{city}&nbsp;{zip}&nbsp;{state}&nbsp;{country}</><br />
            {line1}
          </Typography>
          : <TypographySkeleton loading={isLoading} variant="body2" color="textSecondary">
            No billing address on file. Please click on Plus to add.
          </TypographySkeleton>
        }
      />
    </ListItem>
  );
}

const PaymentMethodsItem = ({ customer, isLoading, onClick }) => {
  const { status, title, description } = customer.payment_method || {};
  const lines = [title, description].filter(i => !!i);

  return (
    <ListItem alignItems="flex-start" secondaryAction={
      <IconButton onClick={onClick} edge="end" aria-label="update" size="large">
        {status === CARD_STATUS_VALID ? <SettingsIcon /> : <AddIcon />}
      </IconButton>
    }>
      <ListItemAvatar>
        <Avatar>
          <PaymentIcon />
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={<Typography variant="body1">Payment Methods</Typography>}
        secondary={!!lines.length
          ? <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: lines.join('<br />') }} />
          : <TypographySkeleton loading={isLoading} variant="body2" color="textSecondary">
            There is no payment source associated with your account. Please click on Plus to add.
          </TypographySkeleton>
        }
      />
    </ListItem>
  );
}

const CreditsItem = ({ customer }) => {
  const { promotional_credits = 0, refundable_credits = 0, unbilled_charges = 0, preferred_currency_code = 'USD' } = customer;

  const credits = [
    { title: 'Promotional Credits', value: promotional_credits },
    { title: 'Renewal Credits', value: refundable_credits },
    { title: 'Unbilled Charges', value: unbilled_charges },
  ].filter(({ value }) => !!value);

  if (!credits.length) { return null }
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar>
          <AccountBalanceWalletIcon />
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography={true}
        secondary={
          credits.map(({ title, value }, i) => (
            <Fragment key={i}>
              <Typography variant="body2" color="textSecondary">{title}</Typography>
              <Currency amount={value} symbol={preferred_currency_code} />
            </Fragment>
          ))
        }
      />
    </ListItem>
  )
}

const TwoFactorItem = ({ onChange, checked = false, isLoading }) => (
  <ListItem alignItems="flex-start" secondaryAction={
    <Switch disabled={isLoading} checked={checked} onChange={onChange}></Switch>
  }>
    <ListItemAvatar>
      <Avatar>
        <PhonelinkLockIcon />
      </Avatar>
    </ListItemAvatar>

    <ListItemText
      disableTypography={true} primary={<Typography variant="body1">Two-factor authentication</Typography>}
      secondary={<Typography variant="body2" color="textSecondary">Manage your 2FA settings here. Enable or disable 2FA for additional security with each login.</Typography>}
    />
  </ListItem>
)