import { useState, useRef, useEffect, DependencyList, Dispatch, SetStateAction } from 'react';

export function useEffectState<S>(initialState: S | (() => S), deps?: DependencyList): [S, Dispatch<SetStateAction<S>>] {
  const mountRef = useRef(false);
  const [value, setValue] = useState(initialState);

  useEffect(() => {
    mountRef.current && setValue(typeof initialState === 'function' ? (initialState as () => S)() : initialState);
    mountRef.current = true;
  }, deps);
  return [value, setValue];
}