import React, { useState, useMemo } from 'react';
import { DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import { List, ListItem, ListItemText, Checkbox, Input, Divider } from '@mui/material';
import { sanitizeTemplateParameters, getDefaultOptionValue } from 'shared/services/chargebee/utils';
import { withModalDialog } from 'src/components/base/ModalDialog';
import { ProductItemMeta, SubscriptionMeta } from '@shared/types/portal/types';

export function usePlanTemplate(products: ProductItemMeta.Product[] = []) {
  return useMemo<ProductItemMeta.Product[]>(() => products.reduce((products, { id, options }) => {
    options?.length && products.push({ id, options });
    return products;
  }, [] as ProductItemMeta.Product[]), [products])
}

type Option = {
  id: string, name: string, description: string,
  value: SubscriptionMeta.Option['value'], type: ProductItemMeta.Option['type'],
};
type OptionGroup = { id: string, options: Option[] };

interface SubscriptionTemplateDialogProps {
  template: ProductItemMeta.Product[];
  params: SubscriptionMeta.Product[];
  onClose: (products?: SubscriptionMeta.Product[]) => void;
}

const SubscriptionTemplateDialogBase: React.FC<SubscriptionTemplateDialogProps> = ({ template, params, onClose }) => {  
  const items = useMemo<OptionGroup[]>(() => {
    const values = new Map<string, SubscriptionMeta.Option['value']>();
    const data = sanitizeTemplateParameters(template, params) ?? params ?? [];

    data.forEach(product =>
      product.options?.forEach(option =>
        values.set(`${product.id}/${option.id}`, option.value)));

    return template.filter(item => item.options?.length)
      .map(({ id, options }) => {
        return {
          id, options: options!.map(option =>
            ({ ...option, value: values.get(`${id}/${option.id}`) ?? getDefaultOptionValue(option) }))
        }
      });
  }, [template, params])

  function handleClose() {
    const products = items.map(({ id, options }) => (
      { id, options: options.map(option => ({ id: option.id, value: option.value })) }
    ));    
    onClose(products);
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">Configurable subscription features</DialogTitle>

      <DialogContent>
        {items.map(({ id, options }) => (
          <List key={id} dense>
            <Divider />
            {options.map((option) => <OptionItem key={option.id} option={option} />)}
          </List>
        ))}
      </DialogContent>

      <DialogActions>
        <Button autoFocus color="primary" onClick={() => onClose()}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={handleClose}>Apply</Button>
      </DialogActions>
    </>
  )
}

interface OptionItemProps {
  option: Option;
}

const OptionItem: React.FC<OptionItemProps> = ({ option }) => {
  const [value, setValue] = useState(option.value);

  function handleChangeValue(value: string | boolean) {    
    option.value = value;
    setValue(value);
  }

  return (
    <ListItem secondaryAction={
      <>
        {option.type === 'boolean' && <OptionBoolean value={value as boolean} onChange={handleChangeValue} />}
        {option.type === 'string' && <OptionString value={value as string} onChange={handleChangeValue} />}
      </>
    }>
      <ListItemText primary={option.name} secondary={option.description} />
    </ListItem>
  )
}

interface OptionProps<T> {
  value?: T
  onChange: (value: T) => void;
}

const OptionBoolean: React.FC<OptionProps<boolean>> = ({ value = false, onChange }) => (
  <Checkbox edge="end" checked={value} onChange={event => onChange(event.target.checked)} />
)

const OptionString: React.FC<OptionProps<string>> = ({ value = '', onChange }) => (
  <Input fullWidth value={value} onChange={event => onChange(event.target.value)} placeholder="Value" />
)

export const SubscriptionTemplateDialog = withModalDialog(SubscriptionTemplateDialogBase, { fullWidth: true, maxWidth: 'sm' });