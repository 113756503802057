import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import accounting from 'accounting';
import { Grid2, Box, Button, InputLabel, TextField, Select, MenuItem } from '@mui/material';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Card, CardActions, CardContent, Typography, SelectChangeEvent } from '@mui/material';

import * as API from '@shared/types/portal/api';

import { runAction } from 'src/services/utils';
import { ReactLink } from 'src/components/base/ReactLink';
import { HeaderLogo } from 'src/components/base/HeaderLogo';
import { MultilineTypography } from 'src/components/base/MultilineTypography';
import { reflect } from 'shared/utils/utils';
import { useStore } from 'src/stores';
import { BILLING_PERIODS } from 'src/services/constants';

export const Checkout = observer(() => {
  const { userStore } = useStore();
  const { isLoading } = userStore;

  const [searchParams] = useSearchParams();
  const { subscription_id } = useParams();

  const originItemOrPriceId = searchParams.get('p');
  const coupon = searchParams.get('coupon') || undefined;

  const [plans, setPlans] = useState<API.Plan.Item[]>([]);
  const [plan, setPlan] = useState<API.Plan.Item>();
  const [itemPrice, setItemPrice] = useState<API.Plan.ItemPrice>();

  const [quantity, setQuantity] = useState(1);
  const [estimate, setEstimate] = useState<API.Checkout.EstimateResponse | undefined>();

  useEffect(() => {
    runAction(async () => {
      await reflect(userStore.logout());
      await userStore.fetchPlans();

      let selectedItem: API.Plan.Item | undefined;
      let selectedItemPrice: API.Plan.ItemPrice | undefined;

      // Find item and itemPrice from given param and filter out 3 years items
      const plans = userStore.plans.reduce((output, item) => {
        const itemPrices = item.items.reduce((output, itemPrice) => {
          if (originItemOrPriceId === itemPrice.id || itemPrice.period !== 3) {
            if (originItemOrPriceId === itemPrice.id) { selectedItemPrice = itemPrice }
            output.push(itemPrice)
          }
          return output;
        }, new Array<API.Plan.ItemPrice>());

        if (itemPrices.length) {
          const updatedItem = { ...item, items: itemPrices };
          if (originItemOrPriceId === item.id || selectedItemPrice) { selectedItem ??= updatedItem }
          output.push(updatedItem);
        }
        return output;
      }, new Array<API.Plan.Item>());

      setPlans(plans);

      if (selectedItem) {
        setPlan(selectedItem);
        setItemPrice(selectedItemPrice ?? selectedItem.items[0]);
      }
    });
  }, []);

  useEffect(() => {
    runAction(async () => {
      let estimate: API.Checkout.EstimateResponse | undefined;
      if (itemPrice?.id) {
        estimate = await userStore.estimateCheckout({ subscription_id, item_price: itemPrice.id, quantity, coupon });
      }
      setEstimate(estimate);
    });
  }, [itemPrice, quantity, subscription_id]);

  function handleChangePlan(event: SelectChangeEvent) {
    setPlan(plans.find(({ id }) => event.target.value === id));
    setItemPrice(undefined);
  }

  function handleChangePriceItem(event: SelectChangeEvent) {
    setItemPrice(plan?.items.find(({ id }) => event.target.value === id));
  }

  function handleCheckout() {
    window.charge.openCheckout({
      layout: 'in_app',
      hostedPage: async () => await userStore.proceedCheckout({
        subscription_id, item_price: itemPrice!.id, quantity, coupon
      }),
      error: (error: any) => userStore.displayErrorSnack(error.message),
    });
  }

  console.log(plan);


  return (
    <Grid2 size={{ xs: 11, md: 7 }} >
      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
            <HeaderLogo />
          </Box>
          {!subscription_id &&
            <p>After you complete the checkout, a new account will be created in our system for you. If you already have an account with us, please <ReactLink to="/">log in here</ReactLink> to purchase a new or manage an existing subscription.</p>
          }
        </CardContent>

        <CardContent>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="plan">Select Plan</InputLabel>
            <Select value={plan?.id ?? ''} onChange={handleChangePlan} inputProps={{ id: 'plan' }} disabled={isLoading}>
              {
                plans.map(i => <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>)
              }
            </Select>
          </FormControl>

          <p /><MultilineTypography variant="caption" text={plan?.description ?? ''} />
        </CardContent>

        <CardContent>
          <FormControl fullWidth component="fieldset" >
            <FormLabel component="legend">Billing Period</FormLabel>
            <RadioGroup aria-label="term" value={itemPrice?.id ?? ''} onChange={handleChangePriceItem}>
              {
                plan?.items.map(itemPrice =>
                  <FormControlLabel key={itemPrice.id} value={itemPrice.id} control={<Radio />}
                    label={BILLING_PERIODS[`${itemPrice.period_unit}_${itemPrice.period}`]} disabled={isLoading}
                  />)
              }
            </RadioGroup>
          </FormControl>
        </CardContent>

        <CardContent>
          <TextField fullWidth label="Number of Licenses" type="number" variant="standard"
            value={quantity} onChange={event => setQuantity(+event.target.value)} disabled={isLoading} />
        </CardContent>

        {!!estimate &&
          <CardContent>
            <Estimate {...estimate} />
          </CardContent>
        }

        <CardActions>
          <Button onClick={handleCheckout} color="secondary" variant="contained" disabled={!itemPrice?.id || isLoading}>Checkout</Button>
        </CardActions>

      </Card>
    </Grid2>
  )
})

interface EstimateProps {
  discount_description?: string;
  currency_code: string;
  total: number;
}

const Estimate: React.FC<EstimateProps> = ({ currency_code, total, discount_description }) => {
  const value = accounting.formatMoney(total / 100, { symbol: currency_code, format: '%s %v' })

  return (
    <Grid2 container alignItems="center" justifyContent="flex-start" direction="row"
      sx={{ minHeight: 48, bgcolor: '#C7F2EB', px: 2, py: 1 }}>
      <Typography variant="button">Total:&nbsp;</Typography>
      <Typography variant="button" sx={{ fontWeight: 800 }}>{value}</Typography>
      {!!discount_description && <Typography>&nbsp;– {discount_description} coupon applied</Typography>}
    </Grid2>
  )
}