import React, { useState, useMemo } from 'react';
import { parseISO } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';

import { Card, CardHeader, CardContent, IconButton, Chip, Tooltip, Button } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemAvatar, ListItemText, Avatar, Toolbar } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, SaveAlt as SaveAltIcon } from '@mui/icons-material';

import { License } from 'src/types/common';
import { SearchField } from 'src/components/base/SearchField';
import { AlertDialog } from 'src/components/base/AlertDialog';
import { LicenseDialog } from './LicenseDialog';
import { useModalDialog } from 'src/components/base/ModalDialog';
import { compareInsensitivelyStrings } from 'shared/utils/utils';

interface OrderedLicense extends License {
  index: number;
}

interface LicenseCardProps {
  licenses: License[];
  onChange: (licenses: License[]) => void;
  onExport?: () => void;
  manageOnly?: boolean;
  disabled: boolean;
}

export const LicenseCard: React.FC<LicenseCardProps> = ({ licenses, onChange, manageOnly = false, disabled, onExport }) => {
  const [pending, setPending] = useState(false);
  const [filter, setFilter] = useState('');
  const debouncedPending = useDebouncedCallback(value => setPending(value), 1000);

  const orderedLicenses = useMemo<OrderedLicense[]>(() =>
    licenses.map((license, index) => ({ ...license, index }))
      .sort((a1, a2) => {
        const v1 = a1.name || a1.license_email || '';
        const v2 = a2.name || a2.license_email || '';

        if ((v1 && !v2) || (!v1 && v2)) {
          return v1 ? -1 : 1;
        }
        return v1.localeCompare(v2, undefined, { sensitivity: 'accent' });
      }),
    [licenses]
  );

  const filteredLicenses = useMemo(() => {
    if (!filter) { return orderedLicenses }

    const val0 = filter.toLowerCase();
    return orderedLicenses.filter(({ name, license_email }) => name?.toLowerCase().includes(val0) || license_email?.toLowerCase().includes(val0));
  }, [orderedLicenses, filter]);

  const licenseDialog = useModalDialog();
  const deleteDialog = useModalDialog();

  async function handleSelectLicense(license?: OrderedLicense) {
    const emails = new Set(licenses
      .filter(({ license_email }) => license_email && !compareInsensitivelyStrings(license_email, license?.license_email))
      .map(({ license_email }) => license_email)
    );

    const modifiedLicense = await licenseDialog.open({ license, validate: email => !emails.has(email) });
    if (modifiedLicense) {
      const index = license?.index ?? licenses.length;
      const result = [...licenses.slice(0, index), modifiedLicense, ...licenses.slice(index + 1)].map<License>(({ index, ...item }) => item);

      onChange(result);
    }
  }

  async function handleDeleteLicense(license: OrderedLicense) {
    if (await deleteDialog.open()) {
      const result = [...licenses.slice(0, license.index), ...licenses.slice(license.index + 1)].map<License>(({ index, ...item }) => item);
      onChange(result);
    }
  }

  function handleExport() {
    setPending(true);
    onExport!()
    debouncedPending(false);
  }

  return (
    <Card sx={{ height: 1 }}>
      <CardHeader title="Licenses" action={
        <Toolbar variant="dense" disableGutters>
          <SearchField value={filter} onChange={setFilter} />
          {onExport &&
            <Tooltip enterDelay={500} placement="top" title="Export licenses">
              <IconButton onClick={handleExport} disabled={pending} size="large"><SaveAltIcon /></IconButton>
            </Tooltip>
          }
          {!manageOnly &&
            <Tooltip enterDelay={500} placement="top" title="Add license">
              <IconButton onClick={() => handleSelectLicense()} size="large"> <AddIcon /> </IconButton>
            </Tooltip>
          }
        </Toolbar>
      } />

      {licenses.length ?
        <List>
          {filteredLicenses.map((license, i) => (
            <LicenseItem key={license.index} avatar={`${i + 1}`} license={license} manageOnly={manageOnly} disabled={disabled}
              onSelectLicense={handleSelectLicense} onDeleteLicense={handleDeleteLicense}
            />
          ))}
        </List>
        : !manageOnly && <CardContent>
          <Button onClick={() => handleSelectLicense()}>Press to add licenses</Button>
        </CardContent>
      }

      <LicenseDialog {...licenseDialog.register()} />

      <AlertDialog {...deleteDialog.register()} title="Delete License" cancelable>
        This action will remove a user slot that has already been paid for from your subscription and credit your account with a prorated amount for such deletion. Are you sure you want to remove this user slot from your subscription?
      </AlertDialog>
    </Card>
  );
}

interface LicenseItemProps {
  license: OrderedLicense;
  avatar: string;
  manageOnly: boolean;
  disabled: boolean;

  onSelectLicense: (license: OrderedLicense) => void;
  onDeleteLicense: (license: OrderedLicense) => void;
}

const LicenseItem: React.FC<LicenseItemProps> = ({ license, avatar, onSelectLicense, onDeleteLicense, manageOnly, disabled }) => {
  const { name, license_email, license_serial, serial_description } = license;
  const isEmpty = !(name || license_serial || license_email);

  const title = isEmpty ? '...' : (name || '');
  const subtitle = [license_email && `Email: ${license_email}`, license_serial && `Hardware: ${serial_description || license_serial}`].filter(Boolean);
  const dateAt = license.checked_at && `Last used: ${parseISO(license.checked_at).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}`;

  return (
    <ListItem disabled={disabled} disablePadding
      secondaryAction={
        <>
          {dateAt && <Chip label={dateAt} size="small" />}
          {!manageOnly &&
            <Tooltip enterDelay={500} placement="top" title={'Delete License'}>
              <IconButton
                disabled={disabled}
                onClick={() => onDeleteLicense(license)}
                aria-label="Delete"
                size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          }
        </>
      }>
      <ListItemButton onClick={() => onSelectLicense(license)}>
        <ListItemAvatar><Avatar>{avatar}</Avatar></ListItemAvatar>

        <ListItemText primary={title}
          slotProps={{ secondary: { children: 'textSecondary' } }}
          secondary={<span dangerouslySetInnerHTML={{ __html: subtitle.join('<br />') }} />}
        />

      </ListItemButton>

    </ListItem>
  );
}