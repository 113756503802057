import { useState, useMemo, useCallback, forwardRef, useRef, ComponentType } from 'react';
import { Dialog, DialogProps as MuiDialogProps } from '@mui/material';

type InternalProps<TResult> = {
  open: boolean;
  onClose: (data?: TResult) => void;
};

type ModalDialogProps<TProps extends object, TResult> = TProps & InternalProps<TResult>;

type DialogComponent<TProps extends object, TResult> = ReturnType<typeof withModalDialog<TProps, TResult>>;

type ExtractProps<T> = T extends DialogComponent<infer P extends object, any> ? P : never;
type ExtractResult<T> = T extends DialogComponent<any, infer R> ? R : never;

interface UseModalDialogResult<TProps extends object, TResult> {
  register: () => ModalDialogProps<TProps, TResult>;
  open: (props: TProps) => Promise<TResult>;
}

export function useModalDialog<T extends DialogComponent<any, any>>(): UseModalDialogResult<ExtractProps<T>, ExtractResult<T>> {
  const promise = useRef<((value: ExtractResult<T>) => void) | null>(null);
  const [open, setOpen] = useState(false);
  const [props, setProps] = useState<ExtractProps<T> | undefined>();

  function handleOnClose(data?: ExtractResult<T>) {
    setOpen(false);
    promise.current?.(data as ExtractResult<T>);
    promise.current = null;
  }

  return useMemo(() => ({
    register: () => ({ open, onClose: handleOnClose, ...(props || {}) } as ModalDialogProps<ExtractProps<T>, ExtractResult<T>>),
    open: async (dialogProps: ExtractProps<T>) => {
      if (promise.current) { throw new Error('useModalDialog: unresolved promise') }

      return new Promise<ExtractResult<T>>((resolve) => {
        promise.current = resolve;
        setProps(dialogProps);
        setOpen(true);
      });
    },
  }), [open, props]);
}

interface WithModalDialogOptions extends Partial<MuiDialogProps> {
  backdrop?: boolean;
}

export function withModalDialog<TProps extends object, TResult = any>(
  WrappedComponent: ComponentType<TProps & { onClose: (data?: TResult) => void }>,
  { backdrop = true, ...dialogProps }: WithModalDialogOptions = {}
) {
  return forwardRef<any, ModalDialogProps<TProps, TResult>>(({ open, onClose, ...props }, ref) => {
    const handleClose = useCallback((_: any, reason: string) => {
      if (!(reason === 'backdropClick' && !backdrop)) {
        onClose();
      }
    }, [onClose]);

    return (
      <Dialog open={open} onClose={handleClose} {...dialogProps}>
        <WrappedComponent ref={ref} {...(props as TProps)} onClose={onClose} />
      </Dialog>
    );
  });
}