import React from 'react';
import accounting from 'accounting';
import { fromUnixTime, format } from 'date-fns';
import { AmountCell } from 'src/components/base/TableCells';

import { DialogTitle, DialogActions, DialogContent, Typography, Button } from '@mui/material';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import { withModalDialog } from 'src/components/base/ModalDialog';
import { StatusChip } from 'src/components/base/StatusChip';
import { Invoice } from 'shared/types/portal/api';

interface InvoiceDialogProps {
  invoice: Invoice.Invoice;
  onClose: (confirm?: boolean) => void;
}

const InvoiceDialogBase: React.FC<InvoiceDialogProps> = ({ invoice, onClose }) => {
  const { id, status, items, total, amount_to_collect, due_date, currency_code } = invoice;
  const date = format(fromUnixTime(due_date!), 'PP');

  return (
    <>
      <DialogTitle id="form-dialog-title">
        <Typography component="span" variant="inherit">Invoice # {id}</Typography><br />
        <InvoiceStatusChip status={status} />&nbsp;<Typography component="span" variant="caption">{`Due Date ${date}`}</Typography>
      </DialogTitle>

      <DialogContent>
        <Table size="small">
          <TableBody>

            {items.map((item, index) => <InvoiceItemRow key={index} item={item} currency={currency_code!} />)}

            <TableRow>
              <TableCell size="medium"><Typography sx={{ fontWeight: 'bold' }} variant="button" color="textPrimary">Total</Typography></TableCell>
              <AmountCell currency={currency_code} amount={Math.abs(total! / 100)} negative={total! < 0} />
            </TableRow>


            <TableRow>
              <TableCell size="medium"><Typography sx={{ fontWeight: 'bold' }} variant="button" color="textPrimary">Amount Due Now</Typography></TableCell>
              <AmountCell currency={currency_code} amount={amount_to_collect! / 100} />
            </TableRow>

          </TableBody>
        </Table>

      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose()} color="primary">Cancel</Button>
        <Button onClick={() => onClose(true)} variant="contained" color="secondary">Confirm</Button>
      </DialogActions>
    </>
  )
}

interface InvoiceItemRowProps {
  item: Invoice.Item;
  currency: string;
}

const InvoiceItemRow: React.FC<InvoiceItemRowProps> = ({ item, currency }) => {
  const { quantity, description, unit_amount, amount } = item;

  const unit_total = accounting.formatMoney(unit_amount / 100, { symbol: currency, format: '%s %v' });
  const details = `(${quantity} x ${unit_total})`;

  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">{description}</Typography>
        <Typography variant="caption" color="textSecondary">{details}</Typography>
      </TableCell>
      <AmountCell currency={currency} amount={Math.abs(amount! / 100)} negative={amount! < 0} />
    </TableRow>
  )
}

const STATUSES: Record<Invoice.Invoice['status'], { label: string, tint: string }> = {
  paid: { label: 'Paid', tint: '#E4FEE9' },
  posted: { label: 'Posted', tint: '#FEFEFE' },
  payment_due: { label: 'Payment Due', tint: '#EE6464' },
  voided: { label: 'Voided', tint: '#FEFEFE' },
  not_paid: { label: 'Not Paid', tint: '#cc3434' },
  pending: { label: 'Pending', tint: '#FEFEFE' },
}

interface InvoiceStatusChipProps {
  status: Invoice.Invoice['status'];
}

export const InvoiceStatusChip: React.FC<InvoiceStatusChipProps> = ({ status }) => {
  const params = STATUSES[status];
  return <StatusChip {...params} />
}

export const InvoiceDialog = withModalDialog(InvoiceDialogBase, { ['aria-labelledby']: 'form-dialog-title' });
