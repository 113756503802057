import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid2, Box } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { runAction } from 'src/services/utils';
import { HeaderLogo } from 'src/components/base/HeaderLogo';
import { useStore } from 'src/stores';

export const CheckoutResult = () => {
  const [status, setStatus] = useState('');
  const { userStore } = useStore();

  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');
  const state = searchParams.get('state');

  console.log(id, state, status);

  useEffect(() => {
    window.charge?.closeAll();

    if (id) {
      runAction(async () => {
        const { status } = await userStore.getCheckout({ id });
        setStatus(status);
      });
    }
  }, [id]);

  return (
    <Grid2 size={{ xs: 11, md: 7 }} >
      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
            <HeaderLogo />
          </Box>
        </CardContent>


        {state === 'succeeded' &&
          <CardContent>

            {status === 'new' &&
              <p>Thank you for your purchase! We’ve created a customer portal account for you and sent you a confirmation email. Please confirm your account to be able to log in to the customer portal.</p>
            }

            {status === 'existing' &&
              <p>Thank you for your purchase! It looks like you already have an account with us. Please log in to the portal with your email address and password below.</p>
            }

          </CardContent>
        }

        {state === 'cancelled' &&
          <CardContent>

          </CardContent>
        }
      </Card>
    </Grid2>
  )
}
