import React from 'react';
import { IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface ReactIconButtonProps extends React.ComponentProps<typeof IconButton> {
  to: string;
}

export const ReactIconButton: React.FC<ReactIconButtonProps> = ({ to, ...props }) => (
  <IconButton component={RouterLink as React.ElementType} to={to} {...props} size="large" />
)