import { useState } from 'react';
import { observer } from 'mobx-react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { Grid2, Box, IconButton, Drawer, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Menu as MenuIcon } from '@mui/icons-material';

import { SearchField } from 'src/components/base/SearchField';
import { Copyright } from 'src/components/base/Copyright';
import { runAction } from 'src/services/utils';
import { reflect } from 'shared/utils/utils';
import { useStore } from 'src/stores';

import headerLogoImage from 'assets/images/logo-header.svg';
import menuLogoImage from 'assets/images/logo-menu.svg';
import { PCILogo } from 'src/components/base/PCILogo';

const tabs = [
  { index: 0, label: 'Subscriptions', url: '/subscriptions' },
  { index: 1, label: 'My Profile', url: '/profile' },
  { index: 2, label: 'Invoices', url: '/invoices' },
];

export const HomeContainer = observer(() => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userStore } = useStore();

  const tabIndex = (tabs.find(({ url }) => location.pathname.startsWith(url)) || tabs[0]).index;
  const { first_name = '', last_name = '', email = '' } = userStore.customer || {};
  const [menuVisible, setMenuVisible] = useState(false);

  function handleSearchChange(event) {
    setSearchParams(event ? { f: event } : '');
  }

  function handleMenuClick() {
    setMenuVisible(!menuVisible);
  }

  async function handleLogoutClick() {
    await runAction(() => reflect(userStore.logout()));
  }

  return (
    <Grid2 container direction="column" alignItems="stretch" wrap="nowrap" sx={{ minHeight: 1, backgroundColor: '#FAFAFA', pb: 1 }}>
      <Paper sx={{ width: 1, backgroundColor: '#FFFFFF' }}>
        <Grid2 container direction="column">
          <Grid2 container alignItems="center">
            <Grid2 size="grow" container direction="row" alignItems="center">
              <IconButton onClick={handleMenuClick} size="large"><MenuIcon /></IconButton>
              <HeaderLogo />
            </Grid2>
            {tabIndex == 1 && <SearchField value={searchParams.get('f') ?? ''} onChange={handleSearchChange} />}
          </Grid2>

          <Grid2 container justifyContent="center">
            <Tabs value={tabIndex} indicatorColor="primary" textColor="primary" centered>
              {tabs.map(item => <Tab key={item.index} label={item.label} href={`/#${item.url}`} />)}
            </Tabs>
          </Grid2>

        </Grid2>
      </Paper>

      <Grid2 container size="grow" alignItems="start" justifyContent="center" sx={{ pt: '2px' }}>
        {userStore.customer && <Outlet />}
      </Grid2>

      <Drawer open={menuVisible} onClose={handleMenuClick}>
        <Grid2 container direction="column" sx={{ width: 334, height: 1 }}>

          <MenuHeader container direction="column" alignItems="flex-start" justifyContent="flex-end" wrap="nowrap">
            <Typography variant="h6" sx={{ color: 'primary.contrastText' }}>{`${first_name} ${last_name}`}</Typography>
            <Typography sx={{ color: 'primary.contrastText' }}>{email}</Typography>
          </MenuHeader>

          <Grid2 size="grow">
            <List component="nav">
              <ListElement title={'Logout'} onClick={handleLogoutClick} />
              <ListElement title={'Privacy'} href="https://www.xappex.com/docs/xappex-privacy-policy/" />
              <ListElement title={'Terms of Use'} href="https://www.xappex.com/?s=Terms+and+Conditions" />
            </List>
          </Grid2>

          <Box sx={{ margin: [0, 3, 3, 3], }}>
            <Grid2 container direction="row" justifyContent='space-between' alignItems='center'><MenuLogo /><PCILogo variant='dark'/></Grid2>
            <Typography variant="caption" color="secondary"><Copyright /><br />All rights reserved.</Typography>
          </Box>
        </Grid2>
      </Drawer>
    </Grid2>
  );
})

const ListElement = ({ title, onClick, href }) => (
  <ListItem
    button disableGutters component="a" href={href} onClick={onClick} target="_blank" rel="noopener">
    <ListItemText sx={{ color: '#507F70', pl: 3 }} primary={title} />
  </ListItem>
)

const HeaderLogo = styled('div')({
  width: 115,
  height: 28,
  marginLeft: '12px',
  background: `url(${headerLogoImage}) no-repeat`,
})

const MenuLogo = styled('div')({
  width: 89,
  height: 22,
  background: `url(${menuLogoImage}) no-repeat`,
})

const MenuHeader = styled(Grid2)({
  height: 96,
  flex: '0 1 96px',
  background: 'linear-gradient(#49C5B1, #507F71)',
  padding: '24px 24px 6px 24px',
})