import React from 'react';
import { Typography } from '@mui/material';

interface MultilineTypographyProps extends React.ComponentProps<typeof Typography> {
  text: string;
}

export const MultilineTypography: React.FC<MultilineTypographyProps> = ({ text, ...props }) => {
  const __html = text.replace(/\n/g, '<br />');
  return <Typography {...props} dangerouslySetInnerHTML={{ __html }} />
}