import { ReactNode } from 'react';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { withModalDialog } from './ModalDialog';

interface AlertDialogProps {
  title: string;
  children: ReactNode;
  cancelable?: boolean;
}

type AlertDialogResult = boolean | undefined;

export const AlertDialog = withModalDialog<AlertDialogProps, AlertDialogResult>(({ title, onClose, cancelable, children }) => (
  <>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" component="div">
        {children}
      </DialogContentText>
    </DialogContent>

    <DialogActions>
      {cancelable
        ? <>
          <Button autoFocus color="secondary" onClick={() => onClose()}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => onClose(true)}>Confirm</Button>
        </>
        : <Button autoFocus variant="contained" color="primary" onClick={() => onClose(true)}>Close</Button>
      }
    </DialogActions>
  </>
),
  { backdrop: false, ['aria-labelledby']: 'alert-dialog-title', ['aria-describedby']: 'alert-dialog-description' }
)