import React, { forwardRef } from 'react';

interface SubmitActionProps {
  action: string;
  target?: string;
  params: Record<string, string>;
}

export const SubmitAction = forwardRef<HTMLFormElement, SubmitActionProps>(({ action, target, params = [] }, ref) => {
  return (
    <form action={action} ref={ref} target={target} method="post">
      {Object.entries(params).map(([name, value]) => <input type="hidden" key={name} name={name} value={value} />)}
    </form>
  )
})