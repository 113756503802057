import React, { useMemo } from 'react';
import { Card, CardHeader, CardContent, Skeleton, IconButton } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { MultilineTypography } from 'src/components/base/MultilineTypography';
import { SubscriptionStaticTitle, SubscriptionSubheader } from './SubscriptionHeaders';
import { SubscriptionFooter } from './SubscriptionFooter';
import { SubscriptionItem } from 'src/types/common';
import { useSubscriptionModel } from 'src/services/subscriptionModel';
import { useStore } from 'src/stores';

interface SubscriptionCardProps {
  subscription: SubscriptionItem;
  onEdit: (subscription: SubscriptionItem) => void;
}

export const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ subscription: initial, onEdit }) => {
  const { userStore } = useStore();
  const { modifySubscription, subscription } = useSubscriptionModel(
    initial, subscription => userStore.modifySubscription(subscription)
  );

  const { key, data } = subscription;

  const { name, description } = useMemo<{ name: string, description?: string }>(() => {
    const itemId = userStore.getItemIdForItemPrice(data.item_price_id!);
    const plan = userStore.plans.find(({ id }) => id === itemId);
    return { name: plan?.name ?? '', description: plan?.description };
  }, [data.item_price_id]);

  const disabled = !userStore.customer!.auto_collection && data.status === 'cancelled';
  const handleEdit = disabled ? undefined : () => onEdit(subscription);

  function handleActivate() {
    modifySubscription({ status: 'active' });
  }

  return (
    <Card key={key} sx={{ mb: 1 }}>
      <CardHeader
        title={<SubscriptionStaticTitle title={data.name ?? name ?? ''} quantity={data.quantity} onClick={handleEdit} />}
        subheader={<SubscriptionSubheader subscription={subscription} />}
        action={!disabled && <IconButton onClick={handleEdit} size="large"><SettingsIcon /></IconButton>}
      />

      <CardContent>
        {!!description
          ? <MultilineTypography variant="caption" text={description} />
          : <Skeleton width="100%" />
        }
      </CardContent>

      <SubscriptionFooter subscription={subscription} onActivate={handleActivate} />

    </Card>
  );
}
