import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Grid2, Button, Alert, AlertTitle } from '@mui/material';
import { Card, CardHeader, CardActions } from '@mui/material';

import { SubscriptionItem, SubscriptionItemData, License } from 'src/types/common';
import { LicenseCard } from './base/LicenseCard';
import { SubscriptionTitle, SubscriptionSubheader } from './base/SubscriptionHeaders';
import { SubmitAction } from 'shared/components/SubmitAction';
import { runAction, runActivity } from 'src/services/utils';
import { getExportSharedSubscriptionUrl } from 'src/api';
import { useStore } from 'src/stores';

export const SharedSubscription = observer(() => {
  const submitFormRef = useRef<HTMLFormElement>();
  const { userStore } = useStore();
  const { token } = useParams();

  const [modified, setModified] = useState(false);
  const [subscription, setSubscription] = useState<SubscriptionItem | undefined>();

  useEffect(() => {
    if (token) {
      runAction(async () => setSubscription(normalizeSubscription(await userStore.fetchSharedSubscription(token))));
    }
  }, [token]);

  function handleChangeName(name: string) {
    updateSubscription({ name });
  }

  function handleChangeLicenses(licenses: License[]) {
    updateSubscription({ licenses });
  }

  function handleResetClick() {
    updateSubscription(subscription!.origin!, false);
  }

  function handleLicenseExport() {
    submitFormRef.current!.submit();
  }

  async function handleSaveSubscription() {
    await runActivity(async () => {
      setSubscription(normalizeSubscription(await userStore.updateSharedSubscription(token!, subscription!)));
      setModified(false);
    })
  }

  function updateSubscription(diff: Partial<SubscriptionItemData>, modify: boolean = true) {
    setSubscription(normalizeSubscription({ ...subscription!, data: { ...subscription!.data, ...diff } }));
    setModified(modify);
  }

  return (
    <Grid2 container size={{ xs: 11, md: 7 }} direction="column" wrap="nowrap" sx={{ height: 1 }} >

      {modified &&
        <Grid2 size={{ xs: "auto" }} sx={{ mb: 1 }} >
          <Alert severity="warning">
            <AlertTitle>Some changes have been made to your subscription(s). You will need to click on Proceed button at the bottom of the screen for the changes to take effect</AlertTitle>
          </Alert>
        </Grid2>
      }

      {subscription &&
        <Grid2 size="grow" container direction="column" rowSpacing={1}>
          <Card>
            <CardHeader
              title={<SubscriptionTitle title={subscription.data.name ?? ''} defaultTitle={'Enter subscription name'} onChange={handleChangeName} />}
              subheader={<SubscriptionSubheader subscription={subscription} />}
            />
          </Card>

          <Grid2 size="grow">
            <LicenseCard licenses={subscription.data.licenses} manageOnly disabled={userStore.isLoading} onChange={handleChangeLicenses} onExport={handleLicenseExport} />
            <SubmitAction ref={submitFormRef} action={getExportSharedSubscriptionUrl()} type='Basic' params={{ id: subscription!.origin!.id, token: token! }} />
          </Grid2>

          <Card>
            <CardActions>
              <Button onClick={handleResetClick} disabled={!modified || userStore.isLoading}>Reset</Button>
              <Button variant="contained" color="secondary"
                onClick={handleSaveSubscription} loading={userStore.isLoading} disabled={!modified}>Proceed</Button>
            </CardActions>
          </Card>
        </Grid2>
      }

      {!token && !subscription && <Alert severity="warning"><AlertTitle>Invalid url</AlertTitle></Alert>}

    </Grid2>
  )
})

function normalizeSubscription(subscription: SubscriptionItem): SubscriptionItem {
  const { licenses = [], quantity } = subscription.data;
  subscription.data.licenses = [...licenses, ...(new Array(Math.max(0, quantity - licenses.length)).fill({ name: '' }))];
  return subscription;
}