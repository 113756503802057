import * as API from 'shared/types/portal/api';

async function request(method: string, uri: string, data?: any, token?: string): Promise<any> {
  try {
    const options: RequestInit = {
      method, credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
        'Pragma': 'no-cache',
      }
    };

    if (token) {
      Object.assign(options.headers!, { Authorization: `Basic ${token}` });
    }

    let url = `${ENV.PORTAL_ENDPOINT}${uri}`;

    if (data) {
      if (method === 'GET') {
        const params = Object.entries(data).filter(([key, value]) => value !== undefined).map(([key, value]) => [key, `${value}`]);
        if (params.length) {
          url += `?${new URLSearchParams(params).toString()}`;
        }
      }
      else {
        options.body = JSON.stringify(data);
      }
    }

    const response = await fetch(url, options);
    if (!response.headers.get('Content-Type')?.includes('application/json')) { throw new Error('Invalid response') }

    if (!response.ok) { throw response }
    return await response.json();
  }
  catch (error: any) {
    const responseError = { code: 'unknown', status: 500, message: error.message };

    if (error instanceof Response) {
      const response = error as Response;
      const data = await response.json();

      Object.assign(responseError, { status: response.status, ...data });
    }
    console.debug('Failed request:', responseError);
    throw responseError;
  }
}

// Customer

export function login(data: API.Customer.LoginInputParam): Promise<API.Customer.CustomerResponse> {
  return request('POST', '/login', data);
}

export function logout(token: string): Promise<void> {
  return request('POST', '/logout', {}, token);
}

export function getEmailBySubscription(data: API.Customer.SubscriptionInputParam): Promise<API.Customer.SubscriptionResponse> {
  return request('GET', '/subscription-email', data);
}

export function registerCustomer(data: API.Customer.CreateCustomerInputParam): Promise<void> {
  return request('POST', '/customer', data);
}

export function activate(data: API.Customer.ActivateCustomerInputParam): Promise<API.Customer.CustomerResponse> {
  return request('POST', '/activate', data);
}

export function resetPassword(data: API.Customer.ResetPasswordInputParam): Promise<void> {
  return request('POST', '/reset-password', data);
}

/**
 * Change customer's password and returns customer's data
 */
export function changePassword(data: API.Customer.ChangePasswordInputParam): Promise<API.Customer.CustomerResponse> {
  return request('POST', '/change-password', data);
}

export function getCustomer(token: string): Promise<API.Customer.CustomerResponse> {
  return request('GET', '/customer', {}, token);
}

export function generatePortalSession(token: string) {
  return request('POST', '/generate_portal_session', {}, token);
}

// 2FA

export function twofactorGenerate(token: string): Promise<API.TFA.GenerateResponse> {
  return request('POST', '/2fa/generate', {}, token);
}

export function twofactorVerify(data: API.TFA.VerifyInputParam, token: string): Promise<void> {
  return request('POST', '/2fa/verify', data, token);
}

export function twofactorDisable(data: API.TFA.VerifyInputParam, token: string): Promise<void> {
  return request('POST', '/2fa/disable', data, token);
}

// Subscriptions

export function fetchSubscriptions(token: string): Promise<API.Subscription.ListResponse> {
  return request('GET', '/subscriptions', {}, token);
}

export function updateSubscription(data: API.Subscription.UpdateInputParam, token: string)
  : Promise<API.Subscription.SubscriptionResponse> {
  return request('PUT', '/subscription', data, token);
}

export function getExportSubscriptionUrl(): string {
  return `${ENV.PORTAL_ENDPOINT}/subscription-export`;
}

export function cancelSubscription(data: API.Subscription.SubscriptionInputParam, token: string)
  : Promise<API.Subscription.SubscriptionResponse> {
  return request('POST', '/subscription-cancel', data, token);
}

export function removeScheduledCancellation(data: API.Subscription.SubscriptionInputParam, token: string)
  : Promise<API.Subscription.SubscriptionResponse> {
  return request('POST', '/subscription-remove-cancellation', data, token);
}

export function estimateSubscription(data: API.Subscription.EstimateInputParam, token: string)
  : Promise<API.Subscription.EstimateResponse> {
  return request('POST', '/subscription-estimate', data, token);
}

export function proceedSubscriptions(data: API.Subscription.ProceedInputParam, token: string)
  : Promise<API.Subscription.ListResponse> {
  return request('POST', '/proceed-subscriptions', data, token);
}

export function shareSubscription(data: API.Subscription.ShareSubscriptionInputParam, token: string)
  : Promise<API.Subscription.ShareSubscriptionResponse> {
  return request('POST', '/subscription-share', data, token);
}

export function fetchSharedSubscription(data: API.Subscription.FetchSharedSubscriptionInputParam)
  : Promise<API.Subscription.SubscriptionResponse> {
  return request('GET', '/shared-subscription', data);
}

export function updateSharedSubscription(data: any)
  : Promise<API.Subscription.SubscriptionResponse> {
  return request('PUT', '/shared-subscription', data);
}

export function getExportSharedSubscriptionUrl(): string {
  return `${ENV.PORTAL_ENDPOINT}/shared-subscription-export`;
}

// Plans

/**
 * Retrieve all available plans for anonymous or logged user
 */
export function fetchPlans(token?: string): Promise<API.Plan.PlanResponse> {
  return request('GET', '/plans', {}, token);
}

// Invoices

export function fetchInvoices(data: API.Invoice.ListInputParam, token: string): Promise<API.Invoice.ListResponse> {
  return request('GET', '/invoices', data, token);
}

export function collectInvoice(data: API.Invoice.CollectInputParam, token: string)
  : Promise<API.Invoice.CollectResponse> {
  return request('POST', '/invoice-collect', data, token);
}

export function getDownloadInvoiceUrl() {
  return `${ENV.PORTAL_ENDPOINT}/invoice-download`;
}

// Checkout

/**
 * Return status of checkout operation
 */
export function getCheckout(data: API.Checkout.CheckoutResultInputParam): Promise<API.Checkout.CheckoutResultResponse> {
  return request('GET', '/checkout', data);
}

/**
 * Proceed checkout
 */
export function checkout(data: API.Checkout.CheckoutInputParam) {
  return request('POST', '/checkout', data);
}

/**
 * Estimate price for selected price item and quantity
 */
export function estimateCheckout(data: API.Checkout.CheckoutInputParam): Promise<API.Checkout.EstimateResponse> {
  return request('POST', '/checkout-estimate', data);
}