import { ProductItemMeta, SubscriptionMeta } from '@shared/types/portal/types';

/**
 * Update subscription product parameters based on product template
 */
export function sanitizeTemplateParameters(template: ProductItemMeta.Product[] = [], params: SubscriptionMeta.Product[] = [])
  : SubscriptionMeta.Product[] | undefined {

  const optionsTemplate = new Set<string>();
  template.forEach(product => product.options?.forEach(option => optionsTemplate.add(`${product.id}/${option.id}`)));

  let isModified = false;
  const modifiedParams = params.reduce((products, product) => {
    const options = product.options.reduce((values, option) => {
      if (optionsTemplate.has(`${product.id}/${option.id}`)) {
        values.push(option);
      }
      else {
        isModified = true;
      }
      return values;
    }, [] as SubscriptionMeta.Option[]);

    if (options.length) {
      products.push({ id: product.id, options });
    }
    return products;
  }, [] as SubscriptionMeta.Product[]);

  return isModified ? modifiedParams : undefined;
}

export function getDefaultOptionValue(option: ProductItemMeta.Option): SubscriptionMeta.Option['value'] {
  if (option.defaultValue) { return option.defaultValue }
  return option.type === 'boolean' ? false : '';
}
