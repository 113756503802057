import { useMemo, forwardRef, useRef } from 'react';
import { fromUnixTime, format } from 'date-fns';
import { observer } from 'mobx-react';
import { Paper, Grid2, Slide, Tooltip } from '@mui/material';
import { Card, CardHeader, CardContent, Typography, Button, IconButton } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { ArrowBack as ArrowBackIcon, Link as LinkIcon, Settings as SettingsIcon } from '@mui/icons-material';

import { useModalDialog, withModalDialog } from 'src/components/base/ModalDialog';
import { LicenseCard } from './base/LicenseCard';
import { AlertDialog } from 'src/components/base/AlertDialog';
import { SubscriptionFooter } from './base/SubscriptionFooter';
import { ShareSubscriptionDialog } from './base/ShareSubscriptionDialog';
import { SubscriptionTemplateDialog, usePlanTemplate } from './base/SubscriptionTemplateDialog';
import { SubscriptionTitle, SubscriptionSubheader } from './base/SubscriptionHeaders';
import { MultilineTypography } from 'src/components/base/MultilineTypography';
import { BottomBar } from 'src/components/base/BottomBar';
import { SubmitAction } from 'shared/components/SubmitAction';
import { SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_IN_TRIAL } from 'shared/services/chargebee/constants';
import { BILLING_PERIODS } from 'src/services/constants';
import { sanitizeTemplateParameters } from 'shared/services/chargebee/utils';
import { getExportSubscriptionUrl } from 'src/api';
import { useEffectState } from 'shared/hooks';
import { useSubscriptionModel } from 'src/services/subscriptionModel';
import { License, SubscriptionItem, Plan } from 'src/types/common';

import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';

interface SubscriptionDialogProps {
  subscription: SubscriptionItem;
  onClose: (subscription?: SubscriptionItem) => void,
}

const SubscriptionDialogBase = observer(({ onClose, subscription: originalSubscription }: SubscriptionDialogProps) => {
  const { userStore } = useStore();
  const submitFormRef = useRef<HTMLFormElement | null>(null);

  const cancelDialog = useModalDialog();
  const removeScheduledCancellationDialog = useModalDialog();
  const shareSubscriptionDialog = useModalDialog();
  const subscriptionTemplateDialog = useModalDialog();

  const { subscription, isModified, ...subscriptionModel } = useSubscriptionModel(originalSubscription);

  // Show only active items or archives and 3-years if subscription requires it
  const plans = useMemo<Plan.Item[]>(() => {
    const itemPriceId = subscription.origin?.item_price_id;
    return userStore.plans.reduce((output, { items, ...item }) => {
      const itemPrices = items.filter(({ status, id, period }) => (status === 'active' && period !== 3) || itemPriceId === id);
      if (itemPrices.length) {
        output.push({ ...item, items: itemPrices });
      }
      return output;
    }, [] as Plan.Item[]);
  }, [subscription.key, userStore.plans]);

  // Select initial plan for the subscription
  const [plan, setPlan] = useEffectState<Plan.Item | undefined>(() => {
    const itemId = subscription.data.item_price_id && userStore.getItemIdForItemPrice(subscription.data.item_price_id);
    if (itemId) { return plans.find(({ id }) => id === itemId) }
  }, [plans, originalSubscription.key]);

  const template = usePlanTemplate(plan?.products);

  // Allow to non profit user have only one non profit subscription
  const isAvailableNonprofit = useMemo(() => {
    const availablePlans = new Map(userStore.plans.map(({ id, nonprofit }) => [id, !!nonprofit]));
    return !userStore.subscriptions.some(({ origin }) => origin
      && (origin.id !== subscription.origin?.id)
      && availablePlans.get(origin.item_price_id!));
  }, [userStore.plans, subscription]);

  const isLoading = userStore.isLoading;
  const scheduled_cancellation = subscription.origin?.scheduled_cancellation;

  function handleChangeName(name: string) {
    subscriptionModel.modifySubscription({ name });
  }

  function handleChangePlan(event: SelectChangeEvent) {
    const planId = event.target.value;
    const plan = plans.find(({ id }) => planId === id)!;

    setPlan(plan);

    const itemPrice = plan.items.length === 1 ? plan.items[0] : undefined;
    subscriptionModel.modifySubscription({ item_price_id: itemPrice?.id });
  }

  function handleChangeTerm(event: SelectChangeEvent) {
    const itemPriceId = event.target.value;
    const itemPrice = plan!.items.find(({ id }) => itemPriceId === id);
    subscriptionModel.modifySubscription({ item_price_id: itemPrice!.id });
  }

  function handleActivateClick() {
    subscriptionModel.modifySubscription({ status: SUBSCRIPTION_STATUS_ACTIVE });
  }

  async function handleShareSubscription() {
    await shareSubscriptionDialog.open({ subscriptionKey: subscription.key });
  }

  async function handleManageTemplate() {    
    const products = await subscriptionTemplateDialog.open({ template, params: subscription.data.products });
    if (products) {
      subscriptionModel.modifySubscription({ products });
    }
  }

  async function handleCancelSubscription() {
    if (await cancelDialog.open()) {
      subscriptionModel.cancelSubscription();
    }
  }

  async function handleRemoveScheduledCancellation() {
    if (await removeScheduledCancellationDialog.open()) {
      subscriptionModel.removeScheduledCancellation();
    }
  }

  function handleChangeLicenses(licenses: License[]) {
    subscriptionModel.modifySubscription({ licenses, quantity: Math.max(licenses.length, 1) });
  }

  function handleLicenseExport() {
    submitFormRef.current!.submit();
  }

  async function handleSaveClick() {
    const { data, origin } = subscription;
    data.products = sanitizeTemplateParameters(template, data.products) ?? data.products;

    if (data.status !== origin?.status || data.item_price_id !== origin?.item_price_id || data.quantity !== origin.quantity) {
      return onClose(subscription);
    }

    await runAction(async () => {
      await userStore.updateSubscription(subscription);
      onClose();
    })
  }

  return <>
    <Grid2 container direction="column" alignItems="center" wrap="nowrap" sx={{ backgroundColor: '#FAFAFA', pb: '56px', flex: '1 0 auto' }}>
      <Paper sx={{ width: 1, height: 48 }}>
        <Grid2 container alignItems="center">
          <IconButton onClick={() => onClose()} size="large"><ArrowBackIcon /></IconButton>
          <Typography variant="h6" noWrap>Subscription Management</Typography>
        </Grid2>
      </Paper>

      <Grid2 container justifyContent="center" size={{ xs: 11, md: 7 }} direction="column" wrap="nowrap" sx={{ mt: 1, flex: '1 0 auto' }}>
        <Grid2 size={{ xs: "auto" }}>
          <Card>
            <CardHeader
              title={<SubscriptionTitle title={subscription.data?.name ?? ''} defaultTitle={'Enter subscription name'} onChange={handleChangeName} />}
              subheader={<SubscriptionSubheader subscription={subscription} />}
              action={
                <>
                  {!!template.length &&
                    <Tooltip title="Configure subscription features">
                      <IconButton onClick={handleManageTemplate} size="large">
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                  }

                  {!!subscription.origin?.id &&
                    <Tooltip title="Delegate User Management">
                      <IconButton onClick={handleShareSubscription} size="large">
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </>
              }
            />
            <CardContent>
              <Grid2 container direction="row" wrap="wrap" justifyContent="space-between" spacing={2}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="plan">Select Plan</InputLabel>
                    <Select value={plan?.id ?? ''} onChange={handleChangePlan} inputProps={{ id: 'plan' }} disabled={isLoading}>
                      {
                        plans.map(i => (
                          <MenuItem key={i.id} value={i.id} disabled={i.nonprofit && !isAvailableNonprofit}>{i.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid2>

                <Grid2 size={{ xs: 12, md: 6 }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="term">Select Billing Period</InputLabel>
                    <Select value={subscription.data.item_price_id ?? ''} onChange={handleChangeTerm}
                      inputProps={{ id: 'term' }} disabled={isLoading || !plan}>
                      {
                        plan?.items.map(term =>
                          <MenuItem key={term.id} value={term.id}>{`${BILLING_PERIODS[`${term.period_unit}_${term.period}`]}`}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid2>
              </Grid2>
            </CardContent>

            <CardContent>
              <MultilineTypography variant="caption" text={plan?.description ?? ''} />
            </CardContent>

            <SubscriptionFooter subscription={subscription} onActivate={handleActivateClick} />

          </Card>
        </Grid2>

        <Grid2 size="grow" sx={{ my: 1 }}>
          <LicenseCard licenses={subscription.data?.licenses ?? []} disabled={isLoading}
            onChange={handleChangeLicenses} onExport={handleLicenseExport}
          />

          {subscription.origin &&
            <SubmitAction ref={submitFormRef} action={getExportSubscriptionUrl()} params={
              { id: subscription.origin.id, type: 'Basic', token: userStore.token! }
            } />
          }
        </Grid2>
      </Grid2>

      <BottomBar position="fixed" color="default">
        <Grid2 container size={{ xs: 11, md: 7 }} alignItems="flex-start" justifyContent="space-between">
          <Grid2>
            <Button color="inherit" disabled={isLoading} onClick={() => onClose()}>Cancel</Button>
            <Button color="primary" disabled={isLoading || !isModified || !subscription.data.item_price_id}
              loading={isLoading} variant="contained" onClick={handleSaveClick} sx={{ ml: 1 }}>Apply</Button>
          </Grid2>
          {!scheduled_cancellation && [SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_IN_TRIAL].includes(subscription.origin?.status ?? '') &&
            <Button color="secondary" disabled={isLoading} loading={isLoading} onClick={handleCancelSubscription}>
              Cancel Subscription
            </Button>
          }
          {scheduled_cancellation &&
            <Button color="secondary" disabled={isLoading} loading={isLoading} onClick={handleRemoveScheduledCancellation}>
              Remove Scheduled Cancellation
            </Button>
          }
        </Grid2>
      </BottomBar>

      <ShareSubscriptionDialog {...shareSubscriptionDialog.register()} />

      <SubscriptionTemplateDialog {...subscriptionTemplateDialog.register()} />

      <AlertDialog {...cancelDialog.register()} title="Cancel Subscription" cancelable>
        Your subscription will remain active until the end of the current period and will cancel automatically on {format(fromUnixTime(subscription.origin?.valid_through ?? 0), 'PPP')}
      </AlertDialog>

      <AlertDialog {...removeScheduledCancellationDialog.register()} title="Confirmation" cancelable>
        Do you want to remove scheduled cancellation?
      </AlertDialog>

    </Grid2>
  </>;
});

export const SubscriptionDialog = withModalDialog(SubscriptionDialogBase, {
  fullScreen: true, TransitionComponent: forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />)
})