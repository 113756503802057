import { useState } from 'react';
import { useEffectState } from 'shared/hooks';
import { useStore } from 'src/stores';
import { runAction } from 'src/services/utils';
import { SubscriptionItem } from 'src/types/common';

/**
 * Provide react hook to manage subscription parameters
 */
export const useSubscriptionModel = (initial: SubscriptionItem, callback?: (item: SubscriptionItem) => void) => {
  const { userStore } = useStore();

  const [isModified, setModified] = useState(false);
  const [subscription, setSubscription] = useEffectState<SubscriptionItem>({ ...initial }, [initial]);

  function modifySubscription(diff: Partial<SubscriptionItem['data']>): void {
    const modifiedSubscription: SubscriptionItem = {
      key: subscription.key, origin: subscription.origin, data: { ...subscription.data, ...diff }
    }

    if (modifiedSubscription.data.status !== subscription.data.status
      || modifiedSubscription.data.item_price_id !== subscription.data.item_price_id
      || modifiedSubscription.data.quantity !== subscription.data.quantity
    ) {
      runAction(async () => {
        modifiedSubscription.estimate = modifiedSubscription.data.item_price_id ? await userStore.estimateSubscription({
          item_price_id: modifiedSubscription.data.item_price_id!, quantity: Math.max(modifiedSubscription.data.quantity, 1),
          subscription_id: subscription.origin?.id
        }) : undefined;

        setSubscription(modifiedSubscription);
        callback?.(modifiedSubscription);
        setModified(true);
      });
    }
    else {
      modifiedSubscription.estimate = {};
      setSubscription(modifiedSubscription);
      callback?.(modifiedSubscription);
      setModified(true);
    }
  }

  function cancelSubscription(): void {
    if (subscription?.origin) {
      runAction(async () => {
        resetSubscription(await userStore.cancelSubscription(subscription.origin!.id));
      });
    }
  }

  function removeScheduledCancellation(): void {
    if (subscription?.origin) {
      runAction(async () => {
        resetSubscription(await userStore.removeScheduledCancellation(subscription.origin!.id));
      });
    }
  }

  function resetSubscription(initial: SubscriptionItem): void {
    setSubscription(initial);
    setModified(false);
  }

  return {
    subscription, isModified,
    modifySubscription, resetSubscription,
    cancelSubscription, removeScheduledCancellation,
  };
};