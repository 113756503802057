import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { TextField, Button, Typography, Box, Skeleton, IconButton, InputAdornment } from '@mui/material';
import { DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { runAction } from 'src/services/utils';
import { withModalDialog } from 'src/components/base/ModalDialog';

import { useStore } from 'src/stores';

export const TwofcatorEnableDialog =  withModalDialog(observer(({ onClose }) => {
  const { userStore } = useStore();

  const [verificationCode, setVerificationCode] = useState('');
  const [secret, setSecret] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleVerify = async () => {
    await runAction(async () => {
      await userStore.twofactorVerify(verificationCode);
      userStore.displaySuccessSnack('Two-factor authentication has been successfully set up')
      onClose({ success: true });
    });
  };

  const handleCopyCodeClick = () => {
    navigator.clipboard.writeText(secret.secret);
    userStore.displaySuccessSnack('Code copied to clipboard');
  };

  useEffect(() => {
    runAction(async () => {
      try {
        setIsLoading(true);
        setSecret(await userStore.twofactorGenerate());
      } finally {
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <>
      <DialogTitle id="form-dialog-title">Set up two-factor authentication</DialogTitle>

      <DialogContent>
        <Typography color="textSecondary" variant="body2" paragraph>You'll need to enter a verification code from your Authenticator app in addition to your password to log in securely.</Typography>
        <Typography color="textSecondary" variant="body2" paragraph>Scan this QR-code with your authentication app.</Typography>

        <Box sx={{ marginBottom: 1 }}>
          <QRCode isLoading={isLoading} value={secret.qr} />
        </Box>

        <TextField fullWidth label="Manual entry code" value={secret.secret ?? ' '} margin="normal" variant="standard"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleCopyCodeClick} edge="end">
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField fullWidth label="Enter the code from your authentication app" variant="filled"
          placeholder="123456" value={verificationCode} margin="normal" onChange={(e) => setVerificationCode(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleVerify} loading={userStore.isLoading} variant="contained" color="primary" disabled={!verificationCode}>Setup Now</Button>
      </DialogActions>
    </>
  )
}), { ['aria-labelledby']: 'form-dialog-title' })

export const TwofcatorDisableDialog = withModalDialog(observer(({ onClose }) => {
  const { userStore } = useStore();
  const [verificationCode, setVerificationCode] = useState('');

  const handleDisable = async () => {
    await runAction(async () => {
      await userStore.twofactorDisable(verificationCode);
      userStore.displaySuccessSnack('Two-factor authentication has been successfully disabled');
      onClose({ success: true });
    })
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Disable two-factor authentication</DialogTitle>

      <DialogContent>
        <Typography color="textSecondary" variant="body2" paragraph>
          To disable two-factor authentication, please enter the verification code from your authenticator app.
        </Typography>

        <TextField fullWidth label="Enter the code from your authentication app" placeholder="123456" margin="normal" variant="filled"
          value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleDisable} loading={userStore.isLoading} variant="contained" color="primary" disabled={!verificationCode}>Disable</Button>
      </DialogActions>
    </>
  )
}), { ['aria-labelledby']: 'form-dialog-title' })

const QRCode = ({ value, isLoading, width = 200, height = 200 }) => (
  <>
    {isLoading
      ? <Skeleton variant="rectangular" width={width} height={height} />
      : <Box sx={{ width: 200, height: 200 }} dangerouslySetInnerHTML={{ __html: value }} />
    }
  </>
)
