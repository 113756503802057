import shortid from 'shortid';
import { makeAutoObservable, runInAction } from 'mobx';
import { registerCustomer, login, logout, activate, resetPassword, changePassword, getCustomer, getEmailBySubscription } from 'src/api';
import { fetchSubscriptions, updateSubscription, estimateSubscription } from 'src/api';
import { cancelSubscription, removeScheduledCancellation } from 'src/api';
import { proceedSubscriptions, generatePortalSession } from 'src/api';
import { shareSubscription, fetchSharedSubscription, updateSharedSubscription } from 'src/api';
import { checkout, estimateCheckout, getCheckout, fetchPlans, fetchInvoices, collectInvoice } from 'src/api';
import { twofactorGenerate, twofactorVerify, twofactorDisable } from 'src/api';
import * as API from 'shared/types/portal/api';
import { SubscriptionItem } from 'src/types/common';
import { reflect } from 'shared/utils/utils';

type ItemCache = { item_id: string, item_price: API.Plan.ItemPrice }

export default class UserStore {
  token: string | null = null;
  customer?: API.Customer.Customer | null = null;

  plans: API.Plan.Item[] = [];
  #itemCache: Map<string, ItemCache> = new Map();

  subscriptions: SubscriptionItem[] = [];
  invoices: API.Invoice.ListResponse[] = [];

  private loading: number = 0;

  snack: { message?: string, variant?: string } = {};

  get isAuthorized() {
    return !!this.token;
  }

  constructor() {
    makeAutoObservable(this);
  }

  async register(data: API.Customer.CreateCustomerInputParam): Promise<void> {
    await registerCustomer(data);
  }

  async resetPassword(data: API.Customer.ResetPasswordInputParam): Promise<void> {
    await resetPassword(data);
  }

  async changePassword(data: API.Customer.ChangePasswordInputParam): Promise<void> {
    const { token, customer } = await changePassword(data);

    await runInAction(async () => {
      await this.fetchPlans(token);

      this.customer = customer;
      this.token = token;
    });
  }

  async login(data: API.Customer.LoginInputParam): Promise<void> {
    const { token, customer } = await login(data);

    await runInAction(async () => {
      await this.fetchPlans(token);
      this.customer = customer;
      this.token = token;
    });
  }

  async activate(data: API.Customer.ActivateCustomerInputParam): Promise<void> {
    const { token, customer } = await activate(data);

    await runInAction(async () => {
      await this.fetchPlans(token);
      this.customer = customer;
      this.token = token;
    });
  }

  async logout(): Promise<void> {
    if (this.token) {
      const token = this.token;
      this.clean();
      await reflect(logout(token));
    }
  }

  async twofactorGenerate(): Promise<API.TFA.GenerateResponse> {
    return await twofactorGenerate(this.token!);
  }

  async twofactorVerify(code: string) {
    await runInAction(async () => {
      await twofactorVerify({ code }, this.token!);
      this.customer!.tfa = true;
    })
  }

  async twofactorDisable(code: string) {
    await runInAction(async () => {
      await twofactorDisable({ code }, this.token!);
      this.customer!.tfa = false;
    })
  }

  async getEmailBySubscription(subscription_id: string): Promise<string> {
    const { email } = await getEmailBySubscription({ subscription_id });
    return email;
  }

  async fetchCustomer(): Promise<void> {
    await runInAction(async () => {
      const [{ token, customer }] = await Promise.all([
        getCustomer(this.token!), this.fetchPlans(),
      ]);

      this.token = token;
      this.customer = customer;
    });
  }

  clean() {
    window.charge && window.charge.logout()

    runInAction(() => {
      this.token = null;
      this.customer = null;

      this.subscriptions = [];
      this.plans = [];
      this.#itemCache.clear();
      this.invoices = [];
    })
  }

  createSubscription(): SubscriptionItem {
    return {
      key: shortid.generate(), data: { status: 'active', quantity: 1, licenses: [], products: [] },
    };
  }

  modifySubscription(subscription: SubscriptionItem): void {
    if (this.subscriptions.find(item => item.key === subscription.key)) {
      this.subscriptions = this.subscriptions.map(item => (item.key === subscription.key ? subscription : item));
    }
    else {
      this.subscriptions = [subscription, ...this.subscriptions];
    }
  }

  resetSubscriptionChanges(): void {
    this.subscriptions = this.subscriptions.filter(({ origin }) => origin?.id).map(item => ({ key: item.key, origin: item.origin!, data: item.origin! }))
  }

  async fetchSubscriptions(): Promise<void> {
    const result = await fetchSubscriptions(this.token!);
    this.subscriptions = result.map(item => ({ key: item.id, origin: item, data: item }));
  }

  async updateSubscription(subscription: SubscriptionItem): Promise<void> {
    const { origin, data } = subscription;

    const result = await updateSubscription({ subscription_id: origin?.id, name: data.name, licenses: data.licenses, products: data.products }, this.token!);
    const updatedItem: SubscriptionItem = { key: result.id, origin: result, data: result };

    this.subscriptions = this.subscriptions.map((item) => item.key === updatedItem.key ? updatedItem : item);
  }

  async cancelSubscription(id: string): Promise<SubscriptionItem> {
    const result = await cancelSubscription({ id }, this.token!);

    const subscription = { key: result.id, origin: result, data: result };
    this.subscriptions = this.subscriptions.map((item) => item.key === subscription.key ? subscription : item);

    return subscription;
  }

  async removeScheduledCancellation(id: string): Promise<SubscriptionItem> {
    const result = await removeScheduledCancellation({ id }, this.token!);

    const subscription = { key: result.id, origin: result, data: result };
    this.subscriptions = this.subscriptions.map((item) => item.key === subscription.key ? subscription : item);

    return subscription;
  }

  async estimateSubscription(params: { item_price_id: string, quantity: number, subscription_id?: string }) {
    const { item_price_id, quantity, subscription_id } = params;
    return await estimateSubscription({ item_price_id, quantity, subscription_id }, this.token!);
  }

  async shareSubscription(id: string, share: boolean): Promise<string | undefined> {
    const { token } = await shareSubscription({ id, share }, this.token!);
    this.subscriptions = this.subscriptions.map(item =>
      item.key === id ? { ...item, origin: { ...item.origin!, shared_token: token } } : item
    );
    return token;
  }

  async fetchSharedSubscription(token: string): Promise<SubscriptionItem> {
    const subscription = await fetchSharedSubscription({ token });
    return { key: subscription.id, origin: subscription, data: subscription };
  }

  async updateSharedSubscription(token: string, subscription: SubscriptionItem): Promise<SubscriptionItem> {
    const { origin, data } = subscription;
    const result = await updateSharedSubscription({
      token, subscription_id: origin?.id, name: data.name, licenses: data.licenses, products: data.products
    });
    return { key: result.id, origin: result, data: result };
  }

  getItemIdForItemPrice(itemPriceId: string): string | undefined {
    const { item_id } = this.#itemCache.get(itemPriceId) ?? {};
    return item_id;
  }

  async fetchPlans(token?: string): Promise<void> {
    const periodToMonths = { day: 1, week: 7, month: 30, year: 365 };

    this.#itemCache.clear();
    this.plans = (await fetchPlans(token ?? this.token ?? undefined))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ items, ...params }) => ({ ...params, items: items.sort((a, b) => a.period! * periodToMonths[a.period_unit] - b.period * periodToMonths[b.period_unit]) }));

    this.plans.forEach(({ id, items }) => items.forEach(item => this.#itemCache.set(item.id, { item_id: id, item_price: item })));
  }

  async fetchInvoices(page = 0, limit = 15): Promise<void> {
    const offset = this.invoices[page - 1]?.next_offset;
    const result = await fetchInvoices({ offset, limit }, this.token!);
    this.invoices = [...this.invoices.slice(0, page), result];
  }

  async collectInvoice(id: string): Promise<void> {
    const invoice = await collectInvoice({ id }, this.token!);

    this.invoices = [...this.invoices.map(({ invoices, next_offset }) => ({
      next_offset,
      invoices: invoices.map(item => item.id === invoice.id ? invoice : item),
    }))];

    await this.fetchCustomer();
  }

  async proceedPayment(): Promise<void> {
    const input: API.Subscription.ProceedSubscription[] = this.subscriptions
      .filter(item => item.estimate)
      .map(({ origin, data: { item_price_id, quantity, licenses, products } }) => ({
        subscription_id: origin?.id, item_price_id, quantity, licenses, products,
      }));

    const result = await proceedSubscriptions(input, this.token!);
    await this.fetchCustomer();

    this.subscriptions = result.map(item => ({ key: item.id, origin: item, data: item }));
  }

  async generatePortalSession() {
    return await generatePortalSession(this.token!);
  }

  async proceedCheckout(data: API.Checkout.CheckoutInputParam) {
    return await checkout(data);
  }

  async getCheckout(data: API.Checkout.CheckoutResultInputParam): Promise<API.Checkout.CheckoutResultResponse> {
    return await getCheckout(data);
  }

  async estimateCheckout(data: API.Checkout.CheckoutInputParam): Promise<API.Checkout.EstimateResponse> {
    return await estimateCheckout(data);
  }

  displaySuccessSnack(message: string) {
    this.snack = { variant: 'success', message };
  }

  displayErrorSnack(message: string) {
    this.snack = { variant: 'error', message };
  }

  cleanSnack() {
    this.snack = {};
  }

  get isLoading(): boolean {
    return this.loading > 0;
  }

  startLoading() {
    this.loading = this.loading + 1;
  }

  stopLoading() {
    this.loading > 0 && this.loading--;
  }
}