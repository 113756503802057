import React from 'react';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface ReactLinkProps extends React.ComponentProps<typeof Link> {
  to: string;
}

export const ReactLink: React.FC<ReactLinkProps> = ({ to, ...props }) => (
  <Link component={RouterLink} to={to} {...props} />
)