import React from 'react';
import accounting from 'accounting';
import { Typography } from '@mui/material';

interface CurrencyProps extends React.ComponentProps<typeof Typography> {
  amount: number;
  symbol?: string;
}

export const Currency: React.FC<CurrencyProps> = ({ amount, symbol = 'USD', ...props }) => (
  <Typography {...props}>{accounting.formatMoney(amount / 100, { symbol, format: '%s %v' })}</Typography>
)