import React from 'react';
import accounting from 'accounting';
import { Typography, TableCell } from '@mui/material';

interface AmountCellProps {
  currency: string;
  amount: number;
  negative?: boolean;
}

export const AmountCell: React.FC<AmountCellProps> = ({ currency, amount = 0, negative = false }) => {
  let total = accounting.formatMoney(amount, { symbol: currency, format: '%s %v' });
  return (
    <TableCell align="right">
      <Typography variant="button" noWrap>{negative ? `(${total})` : total}</Typography>
    </TableCell>
  )
}