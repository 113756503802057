import React from 'react';
import { Typography, Skeleton } from '@mui/material';

interface TypographySkeletonProps extends React.ComponentProps<typeof Typography> {
  loading: boolean;
}

export const TypographySkeleton: React.FC<TypographySkeletonProps> = ({ loading, ...props }) => (
  <>
    {loading
      ? <Skeleton width="100%" />
      : <Typography {...props} />
    }
  </>
)