import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { HashRouter } from 'react-router-dom';
import { GoogleAnalyticsProvider } from 'src/services/googleAnalytics';
import { RootContainer } from 'src/containers/RootContainer';
import { StoreContext, userStore } from 'src/stores';

const theme = createTheme({
  palette: {
    primary: {
      main: '#49C5B1',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#49C5B1',
      contrastText: '#FFFFFF',
    },
  },
});


const StoreProvider = ({ children }) => <StoreContext.Provider value={{ userStore }} children={children} />;

export const App = () => (
  <StoreProvider>
    <ThemeProvider theme={theme}>
      <GoogleAnalyticsProvider measurementId={ENV.GA_TAG} traffic_type={STAGE === 'test' && 'internal'}>
        <CssBaseline />
        <HashRouter>
          <RootContainer />
        </HashRouter>
      </GoogleAnalyticsProvider>
    </ThemeProvider>
  </StoreProvider>
)