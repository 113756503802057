import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Grid2, Paper, Typography, Link } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { ReactIconButton } from 'src/components/base/ReactIconButton';
import { PCILogo } from 'src/components/base/PCILogo';
import { Copyright } from 'src/components/base/Copyright';

import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';

import footerLogoImage from 'assets/images/logo-footer.svg';

const FooterLogoLink = styled(Link)({
  display: 'block',
  width: 88,
  height: 21,
  background: `url(${footerLogoImage}) no-repeat`,
})

export const PrimaryContainer = () => {
  const location = useLocation();
  const { userStore } = useStore();

  useEffect(() => { runAction(async () => await userStore.logout()) }, []);

  const isRoot = location.pathname === '/' || location.pathname.startsWith('/checkout');
  return (
    <Grid2 sx={{ backgroundColor: '#FAFAFA', width: 1, minHeight: 1 }} container direction="column" alignItems="center" justifyContent="center">

      {!isRoot &&
        <Paper sx={{ width: 1, height: 48 }}>
          <Grid2 container alignItems="center">
            <ReactIconButton to="/"><ArrowBackIcon /></ReactIconButton>
            <Typography variant="h6" noWrap>Home</Typography>
          </Grid2>
        </Paper>
      }

      <Grid2 size="grow" sx={{ width: 1, mx: 0, my: 1 }} container alignContent="center" justifyContent="center" alignItems="center" direction="column">
        <Outlet />
      </Grid2>

      <Grid2 container sx={{ width: 1, minHeight: 64, backgroundColor: 'secondary.main', px: 3, py: 1 }} alignItems="center" justifyContent="space-between" wrap="wrap">
        <Grid2 size={{ xs: 'auto' }}>
          <FooterLogoLink href="https://www.xappex.com/" rel="noopener" />
        </Grid2>

        <Grid2 container size="grow" columnSpacing={1} justifyContent="end" alignItems="center">
          <Grid2>
            <Typography sx={{ color: 'secondary.contrastText' }} noWrap><Copyright /> All rights reserved.</Typography>
          </Grid2>
          <Grid2>
            <Link sx={{ color: 'secondary.contrastText' }} href="https://www.xappex.com/docs/xappex-privacy-policy/" underline="none" target="_blank" rel="noopener">Privacy</Link>
          </Grid2>
          <Grid2>
            <Link sx={{ color: 'secondary.contrastText' }} href="https://www.xappex.com/?s=Terms+and+Conditions" underline="none" target="_blank" rel="noopener">Terms of Use</Link>
          </Grid2>
          <Grid2><PCILogo sx={{ marginLeft: '16px' }} variant='light' /></Grid2>
        </Grid2>
      </Grid2>

    </Grid2 >
  )
}