import React, { useState } from 'react';
import { LIVR, translateCode } from 'src/services/validation';
import { omit } from 'shared/utils/utils';
import { withModalDialog } from 'src/components/base/ModalDialog';

import { Grid2, TextField, Button, Chip, Typography } from '@mui/material';
import { DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { License } from 'src/types/common';

interface LicenseDialogProps {
  license: License;
  validate: (email: string) => boolean;
  onClose: (license?: License) => void;
}

const LicenseDialogBase: React.FC<LicenseDialogProps> = ({ license, validate, onClose }) => {
  const [errors, setErrors] = useState({});
  const [model, setModel] = useState<License>({ name: '', license_email: '', license_serial: '', ...license });

  function handleModelChange(event) {
    const { name, value } = event.target;
    setModel(prev => ({ ...prev, [name]: value }));
  }

  function handleAddClick() {
    const validator = new LIVR.Validator({ name: 'string', license_email: ['email', { predicate: (email: string) => validate(email) ? '' : 'DUPLICATED_EMAIL' }] });

    const data = validator.validate(model);
    setErrors(validator.getErrors() || {});

    if (data) { onClose(model) }
  }

  function handleDeleteSerial() {
    setModel(prev => omit(prev, ['license_serial', 'serial_description']));
  }

  return (
    <>
      <DialogTitle id="form-dialog-title">{!license ? "Add License" : "Modify License"}</DialogTitle>

      <DialogContent>
        {!license && <>
          <Typography color="textSecondary" variant="body2" gutterBottom>Use this dialog box to assign a new user to your subscription. Once assigned, an email with installation and activation instructions will go out to the user. A prorated charge for any additional user will be assessed at the day of addition and charged to the credit card on file or added to your renewal invoice if you are using Invoicing for payments.</Typography>
          <Typography color="textSecondary" variant="body2" gutterBottom>XL-Connector users: the specified email address must be the user’s Salesforce account email address.</Typography>
          <Typography color="textSecondary" variant="body2">G-Connector and Google Data Studio Connector users: the specified email address must be the user’s Google account email address.</Typography>
        </>}

        {!!license && <>
          <Typography color="textSecondary" variant="body2" gutterBottom>Use this dialog box to re-assign this license slot to a different user.</Typography>
          <Typography color="textSecondary" variant="body2" gutterBottom>XL-Connector users: the specified email address must be the user’s Salesforce account email address.</Typography>
          <Typography color="textSecondary" variant="body2" gutterBottom>G-Connector and Google Data Studio Connector users: the specified email address must be the user’s Google account email address.</Typography>
          <Typography color="textSecondary" variant="body2">There are no charges for modifying existing user slots.</Typography>
        </>}

        <Grid2 container direction="column">
          <TextField name="name" value={model.name} error={!!errors.name} onChange={handleModelChange}
            type="text" variant="filled" margin="normal" label="Name" autoFocus />

          <TextField name="license_email" value={model.license_email} onChange={handleModelChange}
            error={!!errors.license_email} helperText={translateCode(errors.license_email)}
            type="email" variant="filled" margin="normal" label="Email" />
        </Grid2>

        {model.license_serial &&
          <Chip color="secondary" label={`Hardware: ${model.serial_description || model.license_serial}`} onDelete={handleDeleteSerial} />
        }
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose()} color="primary">Cancel</Button>
        <Button onClick={handleAddClick} variant="contained" color="secondary">{!license ? "Add" : "Save"}</Button>
      </DialogActions>
    </>
  )
}

export const LicenseDialog = withModalDialog(LicenseDialogBase, { ['aria-labelledby']: 'form-dialog-title' });