import { useEffect } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Routes, Route, Navigate, useMatch, useLocation, useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import { MessageSnack } from 'src/components/base/MessageSnack';
import { SignIn } from 'src/components/Customer/SignIn';
import { Register } from 'src/components/Customer/Register';
import { Activate } from 'src/components/Customer/Activate';
import { ResetPassword } from 'src/components/Customer/ResetPassword';
import { ChangePassword } from 'src/components/Customer/ChangePassword';
import { Checkout } from 'src/components/Checkout/Checkout';
import { CheckoutResult } from 'src/components/Checkout/CheckoutResult';
import { SharedSubscription } from 'src/components/Subscriptions/SharedSubscription';
import { Profile } from 'src/components/Profile/Profile';
import { SubscriptionList } from 'src/components/Subscriptions/SubscriptionList';
import { Invoices } from 'src/components/Invoices';
import { PrimaryContainer } from 'src/containers/PrimaryContainer';
import { HomeContainer } from 'src/containers/HomeContainer';

import { useGoogleAnalytics } from 'src/services/googleAnalytics';
import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';

const ActivityStripe = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  zIndex: theme.zIndex.tooltip,
  color: theme.palette.secondary.main,
}))

export const RootContainer = observer(() => {
  const { userStore } = useStore();
  const { snack, isLoading } = userStore;
  const { params = {} } = useMatch('/subscriptions/:subscription_id') ?? {};

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const gtag = useGoogleAnalytics();

  useEffect(() => {
    const page_title = pathname.match(/^(\/\w+)/)?.[0] ?? pathname;
    gtag('event', 'page_view', { page_location: window.location.href, page_title });

    pathname?.startsWith('subscription') && navigate(`/${pathname}`); //WORKARROUND: fix issue with wrong urls from slackbot
  }, [pathname]);

  useEffect(() => {
    window.charge = window.Chargebee.init({ site: ENV.CHARGEBEE_SITE, isItemsModel: true, });
    window.charge.setPortalSession(async () => await userStore.generatePortalSession());

    if (userStore.isAuthorized) { runAction(async () => await userStore.fetchCustomer()) }

  }, []);

  function handleCloseSnack() {
    userStore.cleanSnack();
  }

  return (
    <>
      {isLoading && <ActivityStripe color="secondary" />}
      <Routes>

        <Route path="/" element={<PrimaryContainer />}>
          <Route path="subscription/:token" element={<SharedSubscription />} />
        </Route>

        {!userStore.isAuthorized &&
          <Route path="/" element={<PrimaryContainer />}>
            <Route path="register/:referrer?" element={<Register />} />
            <Route path="reset" element={<ResetPassword />} />
            <Route path="password/:token" element={<ChangePassword />} />
            <Route path="activate/:token" element={<Activate />} />

            <Route path="checkout/:subscription_id?" element={<Checkout />} />
            <Route path="checkout-result" element={<CheckoutResult />} />
            
            <Route index path="*?" element={<SignIn subscription_id={params.subscription_id} />} />
          </Route>
        }

        {userStore.isAuthorized &&
          <Route path="/" element={<HomeContainer />}>
            <Route path="profile" element={<Profile />} />
            <Route path="subscriptions/:subscription_id?" element={<SubscriptionList subscription_id={params.subscription_id} />} />
            <Route path="invoices" element={<Invoices />} />
            <Route index element={<Navigate to="subscriptions" replace={true} />} />
            <Route path="*" element={<Navigate to="subscriptions" replace={true} />} />
          </Route>
        }

      </Routes>
      <MessageSnack open={!!snack.message} snack={toJS(snack)} onClose={handleCloseSnack} />
    </>
  )
})