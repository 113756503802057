import React, { MouseEventHandler } from 'react';
import { fromUnixTime, format } from 'date-fns';
import { Box, Typography, TextField, Chip } from '@mui/material';
import { StatusChip } from 'src/components/base/StatusChip';
import { SubscriptionItem, SubscriptionItemData } from 'src/types/common';

const STATUSES: Record<SubscriptionItemData['status'], { label: string, tint: string } | undefined> = {
  active: { label: 'Active', tint: '#68ac23' },
  in_trial: { label: 'Trial', tint: '#dea928' },
  cancelled: { label: 'Cancelled', tint: '#cc3434' },
  non_renewing: { label: 'Non Renewing', tint: '#575f77' },
  paused: undefined, future: undefined, transferred: undefined,
};

interface SubscriptionSubheaderProps {
  subscription: SubscriptionItem;
}

export const SubscriptionSubheader: React.FC<SubscriptionSubheaderProps> = ({ subscription }) => {
  const { origin, estimate, data } = subscription;

  const valid_through = estimate?.next_billing_at ?? data.valid_through;
  if (!data.status || !valid_through) { return null }

  const st = STATUSES[data.status] ?? STATUSES.active!;
  const valid_though = format(fromUnixTime(valid_through), 'PPP');
  return (
    <>
      <StatusChip {...st} />
      {status !== 'cancelled'
        && <Typography color="textSecondary" variant="caption">&nbsp;Valid through {valid_though}. Activation code: {origin?.activation_code}</Typography>
      }
    </>
  )
}

interface SubscriptionTitleProps {
  title: string;
  defaultTitle: string;
  onChange: (value: string) => void;
}

export const SubscriptionTitle: React.FC<SubscriptionTitleProps> = ({ title = '', defaultTitle = '', onChange }) => (
  <Box sx={{ mb: 1 }}>
    <TextField value={title} placeholder={defaultTitle} variant="standard" onChange={event => onChange(event.target.value)} fullWidth />
  </Box>
)

interface SubscriptionStaticTitleProps {
  title: string;
  quantity?: number;
  onClick?: MouseEventHandler;
}

export const SubscriptionStaticTitle: React.FC<SubscriptionStaticTitleProps> = ({ title = '', quantity, onClick }) => {
  const subtitle = !!quantity && (quantity > 1 ? `${quantity} licenses` : `${quantity} license`);
  return (
    <>
      <Typography sx={{ cursor: onClick ? 'pointer' : 'inherited' }} display="inline" variant='h5' onClick={onClick}>{title}&nbsp;</Typography>
      {subtitle && <Chip size="small" label={subtitle} />}
    </>
  )
}
