import { createRoot } from 'react-dom/client';
import { App } from 'src/app';

const node = document.createElement('div');
node.className = 'app';

document.body.replaceChild(node, document.getElementById('app')!);
if (/MSIE|Trident/.test(window.navigator.userAgent)) {
  node.className = 'placeholder';
  node.innerHTML = 'Unfortunately, Internet Explorer browser is not supported. Please use any of the modern browsers like Google Chrome, Safari, Firefox or Edge to access this page.';
}
else {
  const root = createRoot(node);
  root.render(<App />);
}