import React from 'react';
import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StatusChipProps {
  tint?: string;
  label: string;
}

const ChipStyled = styled(Chip, { shouldForwardProp: prop => prop !== 'color' })<StatusChipProps>(({ theme, tint }) => {
  const { light, contrastText } = theme.palette.augmentColor({ color: { main: tint! } });
  return {
    backgroundColor: light,
    color: contrastText,
    '& .MuiChip-label': {
      textTransform: 'uppercase',
    },
  }
})

export const StatusChip: React.FC<StatusChipProps> = ({ tint, label }) => (
  <ChipStyled label={label} tint={tint} variant="outlined" size="small" />
)