import React from 'react';
import accounting from 'accounting';
import { observer } from 'mobx-react';
import { CardActions, Button, Grid2 } from '@mui/material';

import { SubscriptionItem } from 'src/types/common';

import { MultilineTypography } from 'src/components/base/MultilineTypography';
import { useStore } from 'src/stores';

interface SubscriptionFooterProps {
  subscription: SubscriptionItem;
  onActivate: () => void;
}

export const SubscriptionFooter: React.FC<SubscriptionFooterProps> = observer(({ subscription, onActivate }) => {
  const { userStore } = useStore();
  const { customer } = userStore;

  if (!!subscription.estimate) {
    return <EstimatesBar estimate={subscription.estimate} />
  }

  if (!subscription.origin) { return null }

  const { status, reactivate_option } = subscription.origin;
  if (status !== 'in_trial' && status !== 'cancelled' || (!customer!.auto_collection && status === 'cancelled')) { return null }

  const title = status === 'in_trial' ? 'Convert to paid license'
    : (reactivate_option === 'purchase' ? 'Purchase Subscription' : 'Reactivate Subscription');

  return (
    <CardActions>
      <Button size="small" color="secondary" onClick={onActivate}>{title}</Button>
    </CardActions>
  )
})

interface EstimatesBarProps {
  estimate: NonNullable<SubscriptionItem['estimate']>
}

const EstimatesBar: React.FC<EstimatesBarProps> = ({ estimate }) => {
  const { currency_code, unbilled_charge, credit_note } = estimate;
  const messages = [];

  if (!unbilled_charge?.amount && !unbilled_charge?.voided_amount) {
    messages.push('Modifications will be applied without charges');
  }

  if (unbilled_charge) {
    if (unbilled_charge.amount || unbilled_charge.voided_amount) {
      const total = unbilled_charge.amount - unbilled_charge.voided_amount;
      const value = accounting.formatMoney(Math.abs(total / 100), { symbol: currency_code, format: '%s %v' });
      messages.push(total < 0 ? `<strong>${value}</strong> will be removed from the charges.` : `A charge of <strong>${value}</strong> will be added to the charges.`);

      if (unbilled_charge.discount_amount) {
        const discount_amount = accounting.formatMoney(unbilled_charge.discount_amount / 100, { symbol: currency_code, format: '%s %v' });
        messages.push(`Discount of <strong>${discount_amount}</strong> will be applied.`);
      }
    }
  }

  if (credit_note?.total) {
    const total = accounting.formatMoney(credit_note.total / 100, { symbol: currency_code, format: '%s %v' });
    messages.push(`Renewal credits worth <strong>${total}</strong> will be created.`);
  }

  return (
    <Grid2 container alignItems="flex-start" justifyContent="center" direction="column"
      sx={{ minHeight: 48, backgroundColor: '#C7F2EB', px: 2, py: 1 }}
    >
      <MultilineTypography text={messages.join('\n')} variant="caption" />
    </Grid2>
  )
}
