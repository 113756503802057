import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Grid2, TextField, Typography, Button } from '@mui/material';
import { Card, CardContent, CardActions } from '@mui/material';

import { translateCode, validatorResolver } from 'src/services/validation';
import { PasswordMetter } from 'src/components/base/PasswordMetter';
import { CODE_ACTIVATION_TOKEN_EXPIRED, CODE_TFA_REQUIRED } from 'shared/status-error/codes';
import { scorePassword } from 'shared/passwords/scorePassword';
import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';

export const ChangePassword = observer(() => {
  const { userStore } = useStore();
  const [twofactor, setTwofactor] = useState(false);

  const navigate = useNavigate();
  const { token } = useParams();

  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: validatorResolver({
      password: { predicate: value => scorePassword(value) ? '' : 'TOO_SHORT' },
      password2: ['required', { equal_to_field: 'password' }],
      code: { predicate: value => (twofactor && !value) ? 'REQUIRED' : '' },
    })
  });

  const password = watch('password')
  const passwordScore = scorePassword(password);

  async function handleChangePassword(data) {
    await runAction(async () => {
      try {
        await userStore.changePassword({ password: data.password, token, code: data.code });
        userStore.displaySuccessSnack(`The password was successfully changed`);
      }
      catch (error) {
        if (error.code === CODE_ACTIVATION_TOKEN_EXPIRED) {
          return userStore.displayErrorSnack(`Your activation link has expired. Please click on the ‘Forgot Password?’ link to send you a new one.`);
        }
        if (error.code === CODE_TFA_REQUIRED) { return setTwofactor(true) }
        throw error;
      }
      navigate('/', { replace: true });
    });
  }

  return (
    <Grid2 container size={{ xs: 11, md: 6 }} alignContent="center" justifyContent="center" direction="column">
      <Card>
        <form onSubmit={handleSubmit(handleChangePassword)} noValidate>

          <CardContent>
            <Typography variant="h5" color="textSecondary" gutterBottom>Change Password</Typography>
            <Typography variant="caption">Please enter your new password here.</Typography>

            <TextField name="password" label="Password" autoFocus type="password" fullWidth variant="filled" margin="normal"
              error={!!errors.password} helperText={translateCode(errors.password)} {...register('password')} disabled={twofactor} />

            <PasswordMetter score={passwordScore} empty={!password?.length} />

            <TextField name="password2" label="Retype Password" fullWidth type="password" variant="filled" margin="normal"
              error={!!errors.password2} helperText={translateCode(errors.password2)} {...register('password2')} disabled={twofactor} />

            {twofactor &&
              <TextField name="code" label="Enter the code from your authentication app" placeholder="123456"
                fullWidth variant="filled" margin="normal" autoComplete="off"
                error={!!errors.code} helperText={translateCode(errors.code)} {...register('code')} />
            }
          </CardContent>

          <CardActions>
            <Button loading={userStore.isLoading} type="submit" color="secondary" variant="contained">Change</Button>
          </CardActions>
        </form>
      </Card>
    </Grid2>
  )
})
