import accounting from 'accounting';
import { userStore } from 'src/stores';

export function tryAction(task, suppress = false) {
  return task.catch(async error => {
    if (error.status === 401) { return userStore.clean() }
    if (!suppress) { throw error }
  });
}

export async function runAction(task) {
  try {
    userStore.cleanSnack();
    userStore.startLoading();
    
    return await tryAction(task());
  }
  catch ({ status, code, message }) {
    userStore.displayErrorSnack(message || code);
  }
  finally {
    userStore.stopLoading();
  }
}

export async function runActivity(task) {
  try {
    userStore.cleanSnack();
    userStore.startLoading();
    await tryAction(task());
  }
  finally {
    userStore.stopLoading();
  }
}

export async function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(function () { resolve() }, ms);
  })
}

export function formatCurrency(value, symbol) {
  const codes = { USD: { symbol: '$', format: '%s%s' }, EUR: { symbol: '€', format: '%s%s' } };
  const params = codes[symbol] ?? { symbol, format: '%s %s' };

  return accounting.formatMoney(value, params);
}