import { Link, SxProps, Theme } from '@mui/material';
import logoImage from 'assets/images/pci-logo.png';
import logoImage2 from 'assets/images/pci-logo2.png';

interface PCILogoProps {
  sx?: SxProps<Theme>;
  variant: 'light' | 'dark';
}

export const PCILogo: React.FC<PCILogoProps> = ({ sx, variant }) => (
  <Link
    sx={{
      ...sx, width: 133, height: 26, display: 'block',
      background: `url(${variant === 'dark' ? logoImage2 : logoImage}) no-repeat`,
    }}
    href="//www.securitymetrics.com/site_certificate?id=2142879&tk=540cec4b4faaf9576645945fa56d5a22"
    target="_bank" rel="noopener noreferrer"
  />
)