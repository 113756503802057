import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { FormControl, Button, Tooltip } from '@mui/material';
import { DialogTitle, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { Input, IconButton, InputAdornment } from '@mui/material';
import { Assignment as AssignmentIcon } from '@mui/icons-material';
import { withModalDialog } from 'src/components/base/ModalDialog';
import { runAction } from 'src/services/utils';
import { useEffectState } from 'shared/hooks';
import { useStore } from 'src/stores';
import { SubscriptionItem } from 'src/types/common';

interface ShareSubscriptionDialogProps extends DialogProps {
  subscriptionKey: string;
}

const ShareSubscriptionDialogBase = observer(({ subscriptionKey, onClose }: ShareSubscriptionDialogProps) => {
  const { userStore } = useStore();

  const subscription = useMemo<SubscriptionItem>(() => userStore.subscriptions.find(item => subscriptionKey === item.key)!, [subscriptionKey]);

  const [tooltip, setTooltip] = useState('');
  const [token, setToken] = useEffectState(subscription.origin?.shared_token, [subscription]);
  const shared_url = token ? `${window.location.origin}/#/subscription/${token}` : '';

  function handleCopyLink() {
    navigator.clipboard.writeText(shared_url);
    setTooltip('Link copied');
  }

  async function handleShareClick() {
    await runAction(async () => {
      setToken(await userStore.shareSubscription(subscription.origin!.id, !token));
    })
  }

  return <>
    <DialogTitle id="form-dialog-title">Delegate User Management</DialogTitle>

    <DialogContent>

      {!token &&
        <DialogContentText>If you’d like somebody else to manage users on this subscription, click on the <strong>GENERATE LINK</strong> button below. A shareable link will be generated that you can provide to the person(s) who you’d like to be able to manage users on this subscription. They will not be able to perform any operations that may result in charges to your account. The link will only allow to view and manage user licenses on this subscription.</DialogContentText>
      }

      {!!token &&
        <>
          <DialogContentText gutterBottom>The link below allows people other than the account owner to manage user licenses on this subscription. Share it with anyone who you’d like to delegate license management to.</DialogContentText>
          <DialogContentText gutterBottom>Click on the <strong>REVOKE ACCESS</strong> button below to disable third-party access to this subscription. If you have shared this link with someone – they will not be able to use it after you revoke the link.</DialogContentText>

          <Tooltip title={tooltip} open={!!tooltip} onClose={() => setTooltip('')} leaveDelay={1000} >
            <FormControl fullWidth>
              <Input value={shared_url} type="text" readOnly
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopyLink} size="large"><AssignmentIcon /></IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Tooltip>
        </>
      }

    </DialogContent>

    <DialogActions sx={{ justifyContent: 'space-between' }}>
      <Button onClick={handleShareClick} color="primary" disabled={userStore.isLoading}>
        {!!token ? 'Revoke Access' : 'Generate Link'}
      </Button>

      <Button onClick={() => onClose!({}, 'escapeKeyDown')} variant="contained" color="secondary" disabled={userStore.isLoading}>Done</Button>
    </DialogActions>
  </>;
});


export const ShareSubscriptionDialog = withModalDialog(ShareSubscriptionDialogBase, { maxWidth: 'sm', fullWidth: true, ['aria-labelledby']: 'form-dialog-title' });
